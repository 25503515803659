/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import {Component} from 'react';
import {Link} from 'react-router';
import intl from '@illumio-shared/utils/intl';
import actionCreators from '../../../actions/actionCreators';
import {ServiceStore, SessionStore, SettingsOrgStore} from '../../../stores';
import {RestApiUtils, RenderUtils, ServiceUtils} from '../../../utils';
import {Button, Label, LabelGroup, Icon, Badge, ConfirmationDialog, Spinner} from '../..';
import {getHrefParams} from '../../../lib/api';

export default class ViewRulePanel extends Component {
  constructor(props) {
    super(props);

    this.getRules(0);

    this.handlePrevRule = this.handlePrevRule.bind(this);
    this.handleNextRule = this.handleNextRule.bind(this);
    this.handleDeleteRule = this.handleDeleteRule.bind(this);
    this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);

    this.state = {
      index: 0,
      rule: null,
      ruleset: null,
    };
  }

  getRules(index) {
    const ruleHref = this.props.data.form.rules[index];
    const hrefParams = getHrefParams(ruleHref);

    _.defer(async () => {
      try {
        let ruleset;
        let rule;

        if (ruleHref.includes('enforcement')) {
          const response = await RestApiUtils.enforcementBoundaries.get(
            hrefParams.enforcement_boundary_id,
            hrefParams.pversion,
            true,
            {
              representation: 'labels_workloads_count',
            },
          );

          rule = response.body;
        } else if (ruleHref.includes('/')) {
          const response = await RestApiUtils.ruleSets.getInstance(hrefParams.rule_set_id, hrefParams.pversion, true);

          ruleset = response.body;
          rule = ruleset?.rules.find(rule => rule.href === ruleHref);
        } else {
          const response = await RestApiUtils.essentialServiceRules.get('draft');

          ruleset = response.body;
          rule = ruleset[ruleHref];
        }

        if (rule) {
          // Find specific rule in the ruleset
          this.setState({rule, ruleset});
        } else {
          this.handleClose();
        }
      } catch (error) {
        console.error(error);

        if (error.status === 404 || error.status === 403) {
          this.setState({error: 'permission'});
        } else {
          this.handleClose();
        }
      }
    });
  }

  handleClose() {
    actionCreators.closeActionItem();
    actionCreators.closeDialog();
  }

  async handleDeleteConfirm() {
    const ruleHref = this.props.data.form.rules[this.state.index];
    const hrefParams = getHrefParams(ruleHref);

    if (ruleHref.includes('enforcement')) {
      await RestApiUtils.enforcementBoundaries.delete(hrefParams.enforcement_boundary_id);
    } else {
      await RestApiUtils.secRule.delete(hrefParams.rule_set_id, hrefParams.sec_rule_id);
    }

    actionCreators.updateRuleCoverageForAll();
    actionCreators.updateRingFenceRules();

    this.handleClose();
  }

  handleDeleteRule() {
    actionCreators.openDialog(
      <ConfirmationDialog
        title={intl('Explorer.DeletePolicyTitle')}
        message={intl('Explorer.DeletePolicyMessage')}
        onConfirm={this.handleDeleteConfirm}
      />,
    );
  }

  handlePrevRule() {
    const index = this.state.index - 1;

    this.getRules(index);
    this.setState({index});
  }

  handleNextRule() {
    const index = this.state.index + 1;

    this.getRules(index);
    this.setState({index});
  }

  renderEndpoint(type) {
    const {rule} = this.state;
    let endpoint = null;
    let usesVirtualServices = null;
    let useWorkloadSubnets = null;
    // If the Ruleset has any scope, and the traffic is extrascope, the consumers are extrascope
    const title =
      type === 'consumers'
        ? rule.unscoped_consumers
          ? intl('Map.ExtraScopeConsumers')
          : intl('Common.Sources')
        : intl('Common.Destinations');

    if (
      rule &&
      ((rule.direction === 'egress' && type === 'providers') || (rule.direction === 'ingress' && type === 'consumers'))
    ) {
      endpoint = rule.ip_lists.map(ipList => <Label key={ipList.name} text={ipList.name} icon="ip-list" />);
    } else if (rule.direction) {
      endpoint = null;
    } else if (rule) {
      let items = rule[type];

      items = _.sortBy(items, item => item.label && item.label.key);

      endpoint = items.reduce((result, item) => {
        if (item.label) {
          const {key, value} = item.label;

          if (key === 'role') {
            result.unshift(<Label key={value} text={value} type={key} exclusion={item.exclusion} />);
          } else {
            result.push(<Label key={value} text={value} type={key} exclusion={item.exclusion} />);
          }
        } else if (item.workload) {
          const name = item.workload.name || item.workload.hostname;

          result.push(<Label key={name} text={name} icon="workload" />);
        } else if (item.ip_list) {
          const {name} = item.ip_list;

          result.push(<Label key={name} text={name} icon="ip-list" />);
        } else if (item.virtual_server) {
          const {name} = item.virtual_server;

          result.push(<Label key={name} text={name} icon="virtual-server" />);
        } else if (item.virtual_service) {
          const {name} = item.virtual_service;

          result.push(<Label key={name} text={name} icon="virtual-service" />);
        } else if (item.actors) {
          result.push(<Label icon="all-workloads" text="All Workloads" />);
        } else if (item.label_group) {
          const {key, name} = item.label_group;
          const labelGroup = <LabelGroup key={name} text={name} type={key} exclusion={item.exclusion} />;

          if (key === 'role') {
            result.unshift(labelGroup);
          } else {
            result.push(labelGroup);
          }
        }

        return result;
      }, []);

      if (rule.resolve_labels_as) {
        if (rule.resolve_labels_as[type].includes('virtual_services')) {
          usesVirtualServices = <div>{intl('Common.UsesVirtualServicesWorkloads')}</div>;
        }

        if (_.isEqual(rule.resolve_labels_as[type], ['virtual_services'])) {
          usesVirtualServices = <div>{intl('Common.UsesVirtualServices')}</div>;
        }
      }

      if (rule.use_workload_subnets?.includes(type)) {
        useWorkloadSubnets = <div>{intl('Rulesets.Rules.UseWorkloadSubnets')}</div>;
      }
    }

    return endpoint ? (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <td>
          <div className="MapFormPanel-Row">
            <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              {title}
            </div>
            <div className="MapInfoPanel-Row-Value MapFormPanel-Wrap" data-tid="map-info-panel-row-value">
              {endpoint}
              {usesVirtualServices}
              {useWorkloadSubnets}
            </div>
          </div>
        </td>
      </tr>
    ) : null;
  }

  renderRuleset() {
    const {ruleset, rule} = this.state;
    const orgPolicy = false;
    const hrefParams = getHrefParams(ruleset?.href);

    let title = intl('Common.Ruleset');
    let route = 'rulesets.item';
    let params = {
      id: hrefParams.rule_set_id,
      pversion: hrefParams.pversion,
      tab: rule?.unscoped_consumers ? 'extrascope' : 'intrascope',
    };
    const query = {};
    let rulesetName = orgPolicy
      ? intl('Policy.Organization')
      : RenderUtils.truncateAppGroupName((ruleset || rule).name, 40, [20, 10, 10]);

    if (rule?.href && rule?.href.includes('enforcement')) {
      const ruleParams = getHrefParams(rule.href);

      title = intl('Common.Name');
      route = 'boundaries.item';
      params = {id: ruleParams.enforcement_boundary_id, pversion: 'draft'};
    } else if (!rule?.href) {
      title = intl('Settings.EssentialServiceRule');
      rulesetName = rule.direction === 'egress' ? intl('Common.Outbound') : intl('Common.Inbound');
      route = 'essentialservicerules';
    }

    return (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <td>
          <div className="MapFormPanel-Row">
            <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              {title}
            </div>
            <div className="MapInfoPanel-Row-Value" title={title} data-tid="map-info-panel-row-value">
              {route ? (
                <Link to={route} className="Grid-link" params={params} query={query}>
                  {rulesetName}
                </Link>
              ) : (
                rulesetName
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  }

  renderScopes() {
    const {ruleset} = this.state;

    if (ruleset && ruleset.scopes && (SettingsOrgStore.getAdvancedRuleWriting() || ruleset.scopes[0].length)) {
      const scopes = RenderUtils.getScope(ruleset);

      return (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <td>
            <div className="MapFormPanel-Row">
              <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                {intl('Common.Scopes')}
              </div>
              <div
                className="MapInfoPanel-Row-Value MapFormPanel-Wrap MapFormPanel-Overflow-Visible"
                data-tid="map-info-panel-row-value"
              >
                {scopes}
              </div>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderService() {
    const {rule} = this.state;

    if (rule) {
      if (!rule.ingress_services.length) {
        return (
          <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
            <td>
              <div className="MapFormPanel-Row">
                <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                  {intl('Rulesets.Rules.ProvidingService')}
                </div>
                <div className="MapInfoPanel-Row-Value" data-tid="map-info-panel-row-value">
                  {intl('Rulesets.Rules.DerivedFromProviderVirtualServices')}
                </div>
              </div>
            </td>
          </tr>
        );
      }

      const services = rule.ingress_services.map(service => {
        let content;

        if (service.href) {
          content = (
            <Link
              to="services.item"
              className="Grid-link"
              params={{id: service.href.split('/').pop(), pversion: 'draft'}}
            >
              {(ServiceStore.getSpecified(service.href) || service).name}
            </Link>
          );
        } else if (service.port) {
          content = `${service.port}${service.to_port ? ` - ${service.to_port}` : ''} ${ServiceUtils.lookupProtocol(
            service.proto,
          )}`;
        } else if (service.proto) {
          content = String(ServiceUtils.lookupProtocol(service.proto));
        }

        return (
          <div className="MapInfoPanel-Row-Value-Service" key={service.href || `${service.port} ${service.proto}`}>
            {content}
          </div>
        );
      });

      return (
        <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
          <td>
            <div className="MapFormPanel-Row">
              <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
                {intl('Common.Service')}
              </div>
              <div
                className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Services"
                data-tid="map-info-panel-row-value"
              >
                {services}
              </div>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderSecureConnect() {
    return this.state.rule && this.state.rule.sec_connect ? (
      <tr className="MapInfoPanel-Row" data-tid="map-info-panel-row">
        <td>
          <div className="MapFormPanel-Row">
            <div className="MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
              {intl('Common.SecureConnect')}
            </div>
            <div className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value--Badge" data-tid="map-info-panel-row-value">
              <Badge type="new">On</Badge>
            </div>
          </div>
        </td>
      </tr>
    ) : null;
  }

  render() {
    const {info} = this.props.data;
    const {index, rule, ruleset, error} = this.state;
    const numRules = this.props.data.form.rules.length;
    const {noDelete, modal} = this.props;
    let isReadOnly = this.props.data.form.readOnly;
    let caps;
    let trafficTargetRulesetsIsWritable;
    let action;
    let spinner;

    let type;

    if (rule?.href) {
      type = rule?.href.includes('enforcement') ? 'deny' : 'allow';
      caps = info && (info.caps || (info.targets && info.targets[0].caps) || (info.sources && info.sources[0].caps));
      trafficTargetRulesetsIsWritable = caps?.rulesets.includes('write') || SessionStore.isGlobalEditEnabled();
      spinner = !rule || this.props.data.form.rules[index] !== rule.href;
    } else if (rule) {
      type = 'essential';
      trafficTargetRulesetsIsWritable = false;
      isReadOnly = true;
      spinner = !rule;
    }

    const renderSpinner = (
      <div className="Explorer-Loading">
        <Spinner size="xxlarge" />
      </div>
    );

    if (error === 'permission') {
      return (
        <div className={modal ? 'FormModal' : 'FormPanel'}>
          <div className="FormPanel-Error">
            <div className="FormPanel-Message">{intl('Map.NoPermission')}</div>
            <div className="FormPanel-Button-Bar">
              <Button
                text={modal ? intl('Common.Done') : intl('Common.OK')}
                icon="confirm"
                onClick={this.handleClose}
                tid="close"
              />
            </div>
          </div>
        </div>
      );
    }

    if (spinner) {
      return renderSpinner;
    }

    return (
      <div className={modal ? 'FormModal' : 'FormPanel'}>
        {modal ? null : (
          <div className="FormPanel-Title FormPanel-Draft" onClick={this.handleClose} data-tid="comp-icon-close">
            <Icon name="caret-left" size="xlarge" />
            {type === 'deny' ? intl('Rulesets.DenyRule') : intl('Rule.View')}
          </div>
        )}
        {rule ? (
          <table className="MapInfoPanel MapInfoPanel-NoEdit">
            <tbody>
              {this.renderRuleset()}
              {this.renderScopes()}
              {this.renderEndpoint('consumers')}
              {this.renderSecureConnect()}
              {this.renderService()}
              {this.renderEndpoint('providers')}
              {rule && action}
            </tbody>
          </table>
        ) : (
          renderSpinner
        )}

        {(numRules > 1 || !isReadOnly || trafficTargetRulesetsIsWritable || modal) && (ruleset || type === 'deny') ? (
          <div className={numRules > 1 || modal ? 'FormPanel-Buttons-Bar' : 'FormPanel-Button-Bar'}>
            {!noDelete &&
            numRules &&
            this.state.rule &&
            (!isReadOnly || trafficTargetRulesetsIsWritable) &&
            type !== 'deny' ? (
              <Button
                text={intl('Common.Delete')}
                type="secondary"
                onClick={this.handleDeleteRule}
                tid="view-rule-delete"
              />
            ) : (
              <div />
            )}
            <span className="FormPanel-Right-Buttons">
              {numRules > 1 && (
                <span className="FormPanel-Buttons">
                  <Button
                    text={intl('Common.Prev')}
                    disabled={index === 0}
                    onClick={this.handlePrevRule}
                    tid="view-rule-previous"
                  />
                  <Button
                    text={intl('Common.Next')}
                    disabled={this.state.index === numRules - 1}
                    onClick={this.handleNextRule}
                    tid="view-rule-next"
                  />
                </span>
              )}
              {modal ? (
                <span className="FormPanel-Buttons">
                  <Button
                    text={modal ? intl('Common.Done') : intl('Common.OK')}
                    icon="confirm"
                    onClick={this.handleClose}
                    tid="close"
                  />
                </span>
              ) : null}
            </span>
          </div>
        ) : null}
      </div>
    );
  }
}
