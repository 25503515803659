/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import RuleGraphUtils from './RuleGraphUtils';
import pLimit from 'p-limit';
import {
  TrafficStore,
  WorkloadStore,
  TrafficFilterStore,
  RulesetStore,
  IpListStore,
  ServiceStore,
  LabelGroupStore,
  ExplorerStore,
  SessionStore,
  UserStore,
} from '../stores';
import {LabelUtils, GridDataUtils, RenderUtils, DataValidationUtils, SegmentationTemplateUtils} from '.';
import {execute, getSessionUri, getInstanceUri, isAPIAvailable} from '../lib/api';
import actionCreators from '../actions/actionCreators';
import Constants from '../constants';
import intl from '@illumio-shared/utils/intl';
import {cachedExecute} from '../lib/responseCache';
import {getICMPAndPortlessProtocols} from './PortUtils';

/**
 * Function to set the appropriate variables so that a list page reloads if something has changed
 *
 * @param page One of ['pairingProfileList', 'rulesetList', 'workloadList', 'labelGroupList', 'virtualServiceList', 'ipListList', 'labelList', 'serviceList']
 */
function reloadListPage(page) {
  // If any modification happened to one of the max list pages
  actionCreators.forceListPageRefresh(page, true);
  actionCreators.resetFacetValues(page);
}

function reloadAppGroupData(oldScopes, newScopes) {
  // newScope = [new, new, new] dependent on the different label combinations present on workloads selected.
  // oldScope = [old, old, old]  dependent on the different labels combination present on workloads selected.
  // newScope can only have different role label. Env, loc and App in the same appgroup must be the same, hence comparison with one newScope is enough.

  let reload = false;

  if (newScopes[0] && oldScopes) {
    oldScopes.forEach(oldScope => {
      if (
        oldScope &&
        ((newScopes[0].app && newScopes[0].app.href) !== (oldScope.app && oldScope.app.href) ||
          (newScopes[0].loc && newScopes[0].loc.href) !== (oldScope.loc && oldScope.loc.href) ||
          (newScopes[0].env && newScopes[0].env.href) !== (oldScope.env && oldScope.env.href))
      ) {
        reload = true;
      }
    });
  }

  return reload;
}

// V2 Service API uses "proto" instead of "protocol" as part of improving consistency.
// In addition to that, the "port" key is missing instead of "-1" to denote "All Ports".

/**
 * Function to fix an individual servicePort object (Port Overrides in
 * Virtual Services, Service Ports in Policy Services, etc.)
 */
export function fixServicePortForClient(servicePort) {
  if (!servicePort) {
    return;
  }

  servicePort.protocol = servicePort.proto || servicePort.protocol;

  const notPortlessProtocol = !getICMPAndPortlessProtocols().includes(servicePort.protocol);

  if (notPortlessProtocol && 'port' in servicePort === false) {
    servicePort.port = -1;
  }

  return servicePort;
}

/**
 * Function to fix multiple Services' Service Port objects
 */
export function fixServiceForClient(service) {
  if (!service) {
    return;
  }

  if (service.href) {
    const servicePorts = service.windows_services || service.service_ports;

    if (!servicePorts) {
      return service;
    }

    return servicePorts.map(fixServicePortForClient);
  }

  return fixServicePortForClient(service);
}

/**
 * Function to fix a Port Overrides' Services
 */
function fixPortOverridesServicesForClient(object) {
  if (!object || !object.port_overrides) {
    return;
  }

  object.port_overrides.forEach(fixServicePortForClient);
}

/**
 * Function to fix a Rule's Services
 */
function fixRuleServicesForClient(rule) {
  if (!rule || !rule.ingress_services) {
    return;
  }

  rule.ingress_services.forEach(fixServiceForClient);
}

/**
 * Function to fix multiple Rules' Services
 */
function fixRulesServicesForClient(rules) {
  if (!rules) {
    return;
  }

  rules.forEach(fixRuleServicesForClient);
}

/**
 * Function to fix a Ruleset's Services
 */
function fixRulesetServicesForClient(ruleset) {
  if (!ruleset || !ruleset.rules) {
    return;
  }

  fixRulesServicesForClient(ruleset.rules);
}

/**
 * Function to fix multiple Rulesets' Services
 */
function fixRulesetsServicesForClient(rulesets) {
  if (!rulesets) {
    return;
  }

  rulesets.forEach(fixRulesetServicesForClient);
}

export function fixServicePortForApi(servicePort) {
  if (!servicePort) {
    return;
  }

  if ('protocol' in servicePort) {
    servicePort.proto = servicePort.protocol;
    delete servicePort.protocol;
  }

  if (servicePort.port === -1) {
    delete servicePort.port;
  }

  return servicePort;
}

/**
 * Function to fix service object to be in accordance with v2 API before pushing.
 */
function fixServiceForApi(service) {
  if (!service) {
    return;
  }

  const servicePorts = service.windows_services || service.service_ports;

  if (servicePorts) {
    servicePorts.forEach(fixServicePortForApi);
  }
}

// Optional Features API
export const optionalFeatures = {
  // Get the org information
  getCollection(force) {
    return execute('optional_features', 'get', {
      force,
    });
  },
};

// Orgs API
export const orgs = {
  // Get the org information
  getInstance(id, force) {
    return execute('orgs', 'get_instance', {
      params: {
        id,
      },
      force,
    });
  },
};

export const dns = {
  async dnsReverseLookup(data) {
    const results = [];

    for (const dataChunk of _.chunk(data.ips, 100)) {
      if (ExplorerStore.getDnsLookupInterrupted()) {
        break;
      }

      const result = await execute('dns_reverse_lookup', 'create', {data: {ips: dataChunk}});

      results.push(result);
    }

    return results;
  },
};

// Org API
export const org = {
  // Set the org information
  update(id, data) {
    actionCreators.unselectComponent();
    actionCreators.updateForAll();

    return execute('org', 'update', {
      params: {
        id,
      },
      data,
    }).then(() => {
      orgs.getInstance(id, true /*force fetch*/);
    });
  },
};

export const health = {
  get() {
    if (SessionStore.isUserScoped()) {
      return Promise.resolve();
    }

    return execute('health', 'get');
  },
};

export const samlConfigs = {
  getCollection(query, force) {
    return execute('saml_configs', 'get_collection', {
      query: query || {},
      force,
    });
  },
  getInstance(query, uuid, force) {
    return execute('saml_configs', 'get_instance', {
      query: query || {},
      params: {
        uuid,
      },
      force,
    });
  },
};

export const samlConfig = {
  update(uuid, data) {
    return execute('saml_config', 'update', {
      params: {
        uuid,
      },
      data,
    });
  },
};

export const radiusConfigs = {
  getCollection(query, force) {
    return execute('radius_configs', 'get_collection', {
      query: query || {},
      force,
    });
  },
  async create(data) {
    const resp = await execute('radius_configs', 'create', {data});

    await radiusConfigs.getCollection({}, true);

    return resp;
  },
  getInstance(query, uuid, force) {
    return execute('radius_configs', 'get_instance', {
      query: query || {},
      params: {
        uuid,
      },
      force,
    });
  },
};

export const radiusConfig = {
  verifySecret(uuid, data) {
    return execute('radius_config', 'verify_shared_secret', {
      params: {
        uuid,
      },
      data,
    });
  },
  update(uuid, data) {
    return execute('radius_config', 'update', {
      params: {
        uuid,
      },
      data,
    });
  },
  delete(uuid) {
    return execute('radius_config', 'delete', {
      params: {
        uuid,
      },
    });
  },
};

export const authenticationSettings = {
  get(query, uuid, force) {
    return execute('authentication_settings', 'get', {
      query: query || {},
      params: {
        uuid,
      },
      force,
    });
  },
  update(data) {
    return execute('authentication_settings', 'update', {
      data,
    });
  },
};

// Users API
export const users = {
  getCollection(query, force) {
    return execute('users', 'get_collection', {
      query: query || {},
      force,
    });
  },
  async create(data) {
    const resp = await execute('users', 'create', {data});

    await users.getCollection({}, true);

    return resp;
  },
  // Get the user information
  getInstance(query, id, force) {
    return execute('users', 'get_instance', {
      query: query || {},
      params: {
        id,
      },
      force,
    });
  },
  // Login to pce (/users/login), with token or session cookie
  loginToPCE(token) {
    const options = {};

    if (token) {
      options.headers = {Authorization: `Token ${token}`};
    }

    return execute('users', 'login', options);
  },
  // Log out from pce (/users/logout), with user id
  logoutFromPCE(id) {
    try {
      return execute('user', 'logout', {
        params: {id},
      });
    } catch (error) {
      if (__DEV__) {
        console.error(error);
      }
    }
  },
};

// User API
export const user = {
  async update(userId, data) {
    const resp = await execute('user', 'update', {
      params: {
        id: userId,
      },
      data,
    });

    await Promise.all([users.getCollection({}, true), users.getInstance({}, userId, true)]);

    return resp;
  },
  orgs(query, userId, force) {
    return execute('user', 'orgs', {
      query: query || {},
      params: {
        id: userId,
      },
      force,
    });
  },
  // Logout a specific user and destroy the access token
  logout(id) {
    return execute('user', 'logout', {
      params: {
        id,
      },
    });
  },
  resetPassword(userId, data) {
    return execute('user_local_profile', 'password', {
      params: {
        user_id: userId,
      },
      data,
    });
  },
  async convertExternal(userId) {
    const resp = await execute('user_local_profile', 'create', {
      params: {
        user_id: userId,
      },
    });

    await users.getCollection({}, true);

    return resp;
  },
  async reinvite(userId) {
    const resp = await execute('user_local_profile', 'reinvite', {
      params: {
        user_id: userId,
      },
    });

    await users.getCollection({}, true);

    return resp;
  },
  async delete(userId) {
    const resp = await execute('user_local_profile', 'delete', {
      params: {
        user_id: userId,
      },
    });

    await users.getCollection({}, true);

    return resp;
  },
};

// Auth Sec Principals API
export const authSecPrincipals = {
  // Get the users in the org
  getCollection(query, force) {
    return execute('org_auth_security_principals', 'get_collection', {
      query,
      force,
    });
  },
  async create(data) {
    const resp = await execute('org_auth_security_principals', 'create', {data});

    await authSecPrincipals.getCollection({}, true);

    return resp;
  },
  async update(authSecPrincipalId, data) {
    const resp = await execute('org_auth_security_principal', 'update', {
      params: {
        auth_security_principal_id: authSecPrincipalId,
      },
      data,
    });

    await authSecPrincipals.getCollection({}, true);

    return resp;
  },
  async delete(authSecPrincipalId) {
    const resp = await execute('org_auth_security_principal', 'delete', {
      params: {
        auth_security_principal_id: authSecPrincipalId,
      },
    });

    await authSecPrincipals.getCollection({}, true);

    return resp;
  },
};

// Permissions API
export const permissions = {
  // Get the users in the org
  getCollection(query, force) {
    return execute('org_permissions', 'get_collection', {
      query,
      force,
    });
  },
  getScopeRoles(force) {
    return execute('org_permissions', 'scope_roles', {
      force,
    });
  },
  async create(data) {
    const resp = await execute('org_permissions', 'create', {
      data,
    });

    await Promise.all([permissions.getCollection(true), permissions.getScopeRoles(true)]);

    return resp;
  },
};

// Permissions API
export const permission = {
  async update(permissionId, data) {
    const resp = await execute('org_permission', 'update', {
      params: {
        permission_id: permissionId,
      },
      data,
    });

    await Promise.all([permissions.getCollection(true), permissions.getScopeRoles(true)]);

    return resp;
  },
  async delete(permissionId) {
    const resp = await execute('org_permission', 'delete', {
      params: {
        permission_id: permissionId,
      },
    });

    await Promise.all([permissions.getCollection(true), permissions.getScopeRoles(true)]);

    return resp;
  },
};

// Rule Search APIs
export const ruleSearch = {
  getCollection(data = {}, pversion = 'draft', noCache) {
    const omitPath = [];

    // The API will reject “resolve_actors” if there are no rule actors specified (i.e. the search payload does not have “providers”, “consumers”, or “providers_or_consumers”).
    if (!data.providers && !data.consumers && !data.providers_or_consumers) {
      omitPath.push('resolve_actors');
    }

    // The API will reject “exact_service_match” if “ingress_services” is not specified.
    if (!data.ingress_services) {
      omitPath.push('exact_service_match');
    }

    return execute('sec_policy_rule_search', 'create', {
      params: {pversion},
      data: omitPath.length ? _.omit(data, omitPath) : data,
      noCache,
    }).then(response => {
      if (response && response.body) {
        fixRulesServicesForClient(response.body);
      }

      return response;
    });
  },
};

// Ruleset APIs
export const ruleSets = {
  // Ruleset Facets API for filtering Rulesets
  facets(query, pversion) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('rule_sets', 'facets', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
    });
  },
  // Get the collection of Rulesets
  getCollection(query, pversion, force) {
    return execute('rule_sets', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
      },
      query: query || {},
      force,
    }).then(response => {
      if (response && response.body) {
        fixRulesetsServicesForClient(response.body);
      }

      return response;
    });
  },
  async getPCEUpgradeRuleset({force = false} = {}) {
    // Users with limited scoped read should not access this data, but no need to redirect
    if (__MSP__ || SessionStore.isUserWithReducedScope()) {
      return;
    }

    try {
      const rulesets = await execute('rule_sets', 'get_collection', {
        params: {
          pversion: 'draft',
        },
        query: {
          external_data_set: 'com.illumio.ilo_defaults',
          external_data_reference: 'ilo_non_domain_policies',
          name: intl('Common.PCEUpgradeRulesetName'),
        },
        force,
      });

      if (!rulesets) {
        throw new Error('Unable to fetch rulesets');
      }
    } catch (error) {
      console.warn(error.message);
    }
  },
  // Get Ruleset by ID
  getInstance(id, pversion, force) {
    return execute('rule_sets', 'get_instance', {
      query: {
        representation: 'rule_set_services_labels_and_names',
      },
      params: {
        rule_set_id: id,
        pversion: pversion || 'draft',
      },
      force,
    }).then(response => {
      // TODO: Remove this then block post 18.1
      // If there is an 'active' version available, update that as well
      if (
        pversion === 'draft' &&
        response.body &&
        response.body.update_type !== 'create' &&
        response.body.rules.length < 501
      ) {
        ruleSets.getInstance(id, 'active', true);
      }

      if (response && response.body) {
        fixRulesetServicesForClient(response.body);
      }

      return response;
    });
  },
  // Get Ruleset by ID
  // TODO: Post 18.1, update the above function to call "cachedExecute" instead of "execute"
  // Connectivity Check needs to call cachcedExecute as it may end up making
  // the same call twice due to then block in execute (Connectivity Check makes
  // API getInstance calls to both 'draft' and 'active'. It is not fruitful to
  // decide which 'active' calls not to make there.)
  cachedGetInstance(id, pversion, force) {
    return cachedExecute('rule_sets', 'get_instance', {
      params: {
        rule_set_id: id,
        pversion: pversion || 'draft',
      },
      force,
    }).then(response => {
      // If there is an 'active' version available, update that as well
      if (
        pversion === 'draft' &&
        response.body &&
        response.body.update_type !== 'create' &&
        response.body.rules.length < 501
      ) {
        return ruleSets.cachedGetInstance(id, 'active', true);
      }

      if (response && response.body) {
        fixRulesetServicesForClient(response.body);
      }

      return response;
    });
  },

  // Get rulesets for a set of labels
  getRulesetsForLabels(labelsObject, representation) {
    const labelHrefs = Object.values(labelsObject);

    return ruleSets.getCollection(
      {
        representation,
        labels_scope: JSON.stringify(labelHrefs),
      },
      'draft',
      true,
    );
  },

  // Get App Group Rulesets
  // Group Labels {key: href}
  getGroupCollection(groupLabels, representation) {
    // Query for Rulesets applicable to a group: App: A, Env: E, Loc: L
    // All Global Rulesets: all, all, all
    // Just one of the labels in the scope: A, all, all : all, E, all : all, all, L
    // All but one of the labels in the scope: A, E, all : A, all, L : all, E, L
    // All the labels in the scope: A, E, L

    // Include none of the labels
    const global = this.getCompleteScope({});
    // Include the full scope
    const all = this.getCompleteScope(groupLabels);

    const numLabels = _.size(groupLabels);

    let omit = [];
    let each = [];

    if (numLabels > 1) {
      // Include only one of the labels
      each = _.transform(
        groupLabels,
        (result, clusterLabel, key) => {
          result.push(this.getCompleteScope({[key]: clusterLabel}));
        },
        [],
      );
    }

    if (numLabels > 2) {
      // Omit each of the labels
      const omitLabels = groupLabels;

      omit = _.transform(
        groupLabels,
        (result, clusterLabel, key) => {
          const omittedLabels = {...omitLabels};

          delete omittedLabels[key];
          result.push(this.getCompleteScope(omittedLabels));
        },
        [],
      );
    }

    // Merge them all
    const labels = JSON.stringify([all, ...omit, ...each, global]);

    return ruleSets.getCollection(
      {
        representation,
        labels,
      },
      null,
      true,
    );
  },

  getCompleteScope(labels) {
    const hrefPrefix = getSessionUri(getInstanceUri('labels'), {label_id: ''}).slice(0, -1);
    // Start will all the labels missing
    const noLabels = {
      app: `${hrefPrefix}?key=app&exists=false`,
      env: `${hrefPrefix}?key=env&exists=false`,
      loc: `${hrefPrefix}?key=loc&exists=false`,
    };

    // Replace the missing labels with the included labels
    return _.transform(
      noLabels,
      (result, noLabel, key) => {
        // If this key exists in the set use it, otherwise use the 'noLabels' version
        if (labels[key]) {
          result.push(labels[key]);
        } else {
          result.push(noLabel);
        }
      },
      [],
    );
  },

  // Create new Ruleset
  create(data, pversion) {
    return execute('rule_sets', 'create', {
      params: {
        pversion: pversion || 'draft',
      },
      data,
      timeout: 1_500_000,
    }).then(response => {
      actionCreators.addResource(Constants.RULESET_STORE_ADD, response.body);
      actionCreators.updateRuleCoverageForScope({oldScopes: RuleGraphUtils.getAllScopesFromRuleset(response.body)});

      return response;
    });
  },
  createClean(data, pversion) {
    return execute('rule_sets', 'create', {
      params: {
        pversion: pversion || 'draft',
      },
      data,
    });
  },
  // Delete multiple Rulesets
  delete(ids, pversion) {
    const oldScopes = [];

    _.each(ids, href => {
      const scopes = RuleGraphUtils.getScopeByRulesetHref(href);

      RuleGraphUtils.mergeScopes(oldScopes, scopes);
    });

    return execute('rule_sets', 'delete', {
      params: {
        pversion: pversion || 'draft',
      },
      data: {
        rule_sets: ids.map(href => ({href})),
      },
    }).then(response => {
      ids.forEach(href => {
        const ruleset = RulesetStore.getSpecified(href);

        if (!ruleset) {
          return;
        }

        if (ruleset.update_type === 'create') {
          actionCreators.deleteResource(Constants.RULESET_STORE_DELETE, href);
        } else {
          ruleSets.getInstance(GridDataUtils.getIdFromHref(href), 'draft', true);
        }
      });

      actionCreators.updateRuleCoverageForScope({oldScopes});

      return response;
    });
  },
  projectedVes(id, data) {
    return execute('rule_set', 'projected_ves', {
      params: {
        pversion: 'draft',
        rule_set_id: id,
      },
      data,
    });
  },
  projectedVesCreate(data) {
    return execute('rule_sets', 'projected_ves', {
      params: {
        pversion: 'draft',
      },
      data,
    });
  },
};

// Services API
export const services = {
  // Used in Object Selector
  autocomplete(pversion = 'draft', query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('services', 'autocomplete', {
      params: {
        pversion,
      },
      query,
    }).then(response => {
      // The autocomplete API returns the "All Services" Service which the UI has to hide
      // The num_matches count remains unaffected as "All Services" should reflect in the count
      const allServicesIndex = response.body.matches.findIndex(item => item.name === intl('Common.AllServices'));

      if (allServicesIndex > -1) {
        response.body.matches.splice(allServicesIndex, 1);
      }

      return response;
    });
  },
  // Services Facets API for filtering Services
  facets(query, pversion) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('services', 'facets', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
    });
  },
  // Get the collection of Services
  getCollection(query = {}, pversion, force, noStore) {
    return execute('services', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
      },
      query: {...query, max_results: query.max_results || 500},
      force,
      noStore,
    }).then(response => {
      if (response && response.body) {
        response.body.forEach(fixServiceForClient);
      }

      return response;
    });
  },
  // Get Service by id
  getInstance(id, pversion, force) {
    return execute('services', 'get_instance', {
      params: {
        service_id: id,
        pversion: pversion || 'draft',
      },
      force,
    }).then(response => {
      if (response && response.body) {
        fixServiceForClient(response.body);
      }

      return response;
    });
  },
  // Create new Service
  create(data, pversion = 'draft', refresh = true) {
    fixServiceForApi(data);

    return execute('services', 'create', {
      params: {pversion},
      data,
    }).then(response => {
      reloadListPage('serviceList');

      if (refresh) {
        services.getCollection({}, 'draft', true);
      }

      if (response && response.body) {
        fixServiceForClient(response.body);
      }

      return response;
    });
  },
  // Delete multiple services
  delete(servicesArr, pversion) {
    return execute('services', 'delete', {
      params: {
        pversion: pversion || 'draft',
      },
      data: {
        services: servicesArr,
      },
    }).then(response => {
      reloadListPage('serviceList');
      services.getCollection({} /*query*/, 'draft', true /*force fetch*/);

      return response;
    });
  },
};

// Service API
export const service = {
  // Modify Service
  update(id, data, pversion) {
    fixServiceForApi(data);

    const href = getSessionUri(getInstanceUri('services'), {pversion: 'draft', service_id: id});
    const service = ServiceStore.getSpecified(href);

    if (
      service &&
      service.external_data_set === 'illumio_segmentation_templates' &&
      !data.external_data_reference &&
      DataValidationUtils.services.hasChanged(service, data, true)
    ) {
      const dataRef = DataValidationUtils.dataReference.formatEditedState(service.external_data_reference);

      data.external_data_reference = dataRef;
    }

    return execute('service', 'update', {
      params: {
        service_id: id,
        pversion: pversion || 'draft',
      },
      data,
    })
      .then(() => {
        services.getInstance(id, 'draft', true /*force fetch*/);
      })
      .then(response => {
        // when a service's port range is updated, we would have to
        // re-render the whole graph - expensive ):
        actionCreators.updateRuleCoverageForAll();

        if (response && response.body) {
          fixServiceForClient(response.body);
        }

        return response;
      });
  },
  // Delete Service
  delete(id, pversion) {
    return execute('service', 'delete', {
      params: {
        service_id: id,
        pversion: pversion || 'draft',
      },
    }).then(response => {
      reloadListPage('serviceList');
      services.getCollection({} /*query*/, 'draft', true /*force fetch*/);

      return response;
    });
  },
};

// Ruleset API
export const ruleSet = {
  // Modify Ruleset
  update(id, data) {
    const href = getSessionUri(getInstanceUri('rule_sets'), {pversion: 'draft', rule_set_id: id});
    const ruleset = RulesetStore.getSpecified(href);

    if (
      ruleset &&
      ruleset.external_data_set === 'illumio_segmentation_templates' &&
      !data.external_data_reference &&
      (!data.name || data.enabled !== ruleset.enabled || data.description !== ruleset.description)
    ) {
      const dataRef = DataValidationUtils.dataReference.formatEditedState(ruleset.external_data_reference);

      data.external_data_reference = dataRef;
    }

    const oldScopes = RuleGraphUtils.getScopeByRulesetId(id);

    return execute('rule_set', 'update', {
      params: {
        rule_set_id: id,
        pversion: 'draft',
      },
      data,
    }).then(() => {
      ruleSets.getInstance(id, 'draft', true);
      actionCreators.updateRuleCoverageForScope({rulesetId: id, oldScopes});
    });
  },
  updateClean(id, data) {
    return execute('rule_set', 'update', {
      params: {
        rule_set_id: id,
        pversion: 'draft',
      },
      data,
      timeout: 1_500_000,
    });
  },
  // Delete Ruleset
  delete(href, pversion) {
    const id = GridDataUtils.getIdFromHref(href);
    const oldScopes = RuleGraphUtils.getScopeByRulesetId(id);

    return execute('rule_set', 'delete', {
      params: {
        rule_set_id: id,
        pversion: pversion || 'draft',
      },
    }).then(response => {
      ruleSets.getInstance(id, 'draft', true).catch(err => {
        if (err.status === 404) {
          // 404 means resource deleted
          actionCreators.deleteResource(Constants.RULESET_STORE_DELETE, href);
        }
      });
      actionCreators.updateRuleCoverageForScope({rulesetId: id, oldScopes});

      return response;
    });
  },
  // Get commits where this rule set was changed
  commitHistory(id, pversion) {
    return execute('rule_set', 'commit_history', {
      params: {
        rule_set_id: id,
        pversion: pversion || 'draft',
      },
    });
  },
};

// Sec Policies API
export const secPolicies = {
  // Get the collection of committed security policies
  getCollection(force) {
    return execute('sec_policies', 'get_collection', {
      force,
    });
  },
  // Get security policy by version
  getInstance(pversion, force) {
    return execute('sec_policies', 'get_instance', {
      params: {
        pversion: pversion || 'draft',
      },
      force,
    });
  },
  // Commit current draft
  create(data) {
    return execute('sec_policies', 'create', {
      data,
    });
  },
  // Get dependencies
  dependencies(data) {
    if (!data) {
      return execute('sec_policy_pending', 'get', {});
    }

    return execute('sec_policy', 'dependencies', {
      params: {
        pversion: 'draft',
      },
      data,
    });
  },

  // Revert current draft provisioning
  delete(data) {
    return execute('sec_policies', 'delete', {
      data,
    }).then(() => {
      actionCreators.updateTrafficForAll();
      services.getCollection({} /*query*/, 'draft', true /*force fetch*/);
      ruleSets.getCollection({representation: 'rule_set_services_labels_and_names'}, 'draft', true);
    });
  },

  policyView(query = {}, force = false) {
    return execute('sec_policy', 'policy_view', {
      query,
      // Only "active" pversion is allowed, thus hardcoded here
      params: {pversion: 'active'},
      force,
    });
  },
};

// Sec Policy API
export const secPolicy = {
  // Get the collection of modified objects in this policy
  modifiedObjects(pversion) {
    return execute('sec_policy', 'modified_objects', {
      params: {
        pversion: pversion || 'draft',
      },
    });
  },

  // Determine if the policy allows two workloads to communicate
  allow(query, pversion, options = {}) {
    return cachedExecute('sec_policy_allows', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
      timeout: 600_000, // 10 min timeout, because backend can be very slow on policy check
      ...options,
    });
  },
};

// Security Rules APIs
export const secRules = {
  // Get Security Rule by ID
  getInstance(rulesetId, id, pversion, force) {
    return execute('sec_rules', 'get_instance', {
      params: {
        sec_rule_id: id,
        rule_set_id: rulesetId,
        pversion: pversion || 'draft',
      },
      force,
    }).then(response => {
      if (response && response.body) {
        fixRuleServicesForClient(response.body);
      }

      return response;
    });
  },
  // Create new Security Rule
  create(rulesetId, data, pversion) {
    return execute('sec_rules', 'create', {
      params: {
        rule_set_id: rulesetId,
        pversion: pversion || 'draft',
      },
      data,
    }).then(response => {
      ruleSets.getInstance(rulesetId, 'draft', true /*force fetch*/).then(rulesetResponse => {
        if (
          rulesetResponse &&
          rulesetResponse.body &&
          rulesetResponse.body.external_data_set === 'illumio_segmentation_templates'
        ) {
          const dataRef = DataValidationUtils.dataReference.formatEditedState(
            rulesetResponse.body.external_data_reference,
          );

          if (SegmentationTemplateUtils.isEntityEdited(dataRef)) {
            ruleSet.update(rulesetId, {external_data_reference: dataRef}).then(() => {
              ruleSets.getInstance(rulesetId, 'draft', true /*force fetch*/);
            });
          }
        }
      });
      secPolicies.dependencies();

      const rule = response.body;

      actionCreators.updateRuleCoverageForScope({rulesetId, rule});

      return response;
    });
  },
  deleteClean(rulesetId, rules, pversion = 'draft') {
    return execute('sec_rules', 'delete', {
      params: {
        rule_set_id: rulesetId,
        pversion: pversion || 'draft',
      },
      data: rules,
    });
  },
};

export const secRule = {
  // Modify Security Rule
  update(rulesetId, id, data) {
    const scopesAndNodes = RuleGraphUtils.getScopesAndNodesByRuleId(rulesetId, id);

    return execute('sec_rule', 'update', {
      params: {
        rule_set_id: rulesetId,
        sec_rule_id: id,
        pversion: 'draft',
      },
      data,
    })
      .then(() => secRules.getInstance(rulesetId, id, 'draft', true /*force fetch*/))
      .then(response => {
        ruleSets.getInstance(rulesetId, 'draft', true /*force fetch*/).then(rulesetResponse => {
          if (
            rulesetResponse &&
            rulesetResponse.body &&
            rulesetResponse.body.external_data_set === 'illumio_segmentation_templates'
          ) {
            const dataRef = DataValidationUtils.dataReference.formatEditedState(
              rulesetResponse.body.external_data_reference,
            );

            if (SegmentationTemplateUtils.isEntityEdited(dataRef)) {
              ruleSet.update(rulesetId, {external_data_reference: dataRef}).then(() => {
                ruleSets.getInstance(rulesetId, 'draft', true /*force fetch*/);
              });
            }
          }
        });

        secPolicies.dependencies();

        const rule = response.body;
        const oldScopes = scopesAndNodes.scopes;
        const oldNodes = scopesAndNodes.nodes;

        actionCreators.updateRuleCoverageForScope({rulesetId, rule, oldScopes, oldNodes});
        (rule.ingress_services || []).forEach(fixServiceForClient);

        return response;
      });
  },
  // Delete Security Rule
  delete(rulesetId, id, pversion) {
    const scopesAndNodes = RuleGraphUtils.getScopesAndNodesByRuleId(rulesetId, id);

    return execute('sec_rule', 'delete', {
      params: {
        rule_set_id: rulesetId,
        sec_rule_id: id,
        pversion: pversion || 'draft',
      },
    }).then(response => {
      ruleSets.getInstance(rulesetId, 'draft', true /*force fetch*/);

      secPolicies.dependencies();

      const oldScopes = scopesAndNodes.scopes;
      const oldNodes = scopesAndNodes.nodes;

      actionCreators.updateRuleCoverageForScope({oldScopes, oldNodes});

      return response;
    });
  },
};

// Security Principals (User Groups) APIs
export const securityPrincipals = {
  // Used in Object Selector in Rulesets page, etc
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('security_principals', 'autocomplete', {
      query,
    });
  },
  // Security Principals Facets API for filtering User Groups
  facets(query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('security_principals', 'facets', {
      query,
    });
  },
  // Return the set of user groups
  getCollection(query = {}, force) {
    return execute('security_principals', 'get_collection', {
      query,
      force,
    });
  },
  // Create a new user group
  create(data) {
    return execute('security_principals', 'create', {
      data,
    }).then(response => {
      securityPrincipals.getCollection({}, true);

      return response;
    });
  },
  // Get user group by sid
  getInstance(sid, force) {
    return execute('security_principals', 'get_instance', {
      params: {
        sid,
      },
      force,
    });
  },
};

export const securityPrincipal = {
  // Modify a user group by sid
  update(sid, data) {
    return execute('security_principal', 'update', {
      params: {
        sid,
      },
      data,
    }).then(response => {
      securityPrincipals.getInstance(sid, true);

      return response;
    });
  },
  // Delete a label by ID
  delete(sid) {
    return execute('security_principal', 'delete', {
      params: {
        sid,
      },
    }).then(response => {
      securityPrincipals.getCollection({}, true);

      return response;
    });
  },
};

// Label APIs
export const labels = {
  // Used in Object Selector in Rulesets page, etc
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('labels', 'autocomplete', {
      query,
    });
  },
  // Labels Facets API for filtering Labels
  facets(query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('labels', 'facets', {
      query,
    });
  },
  // Return the set of labels
  getCollection(query, force) {
    return execute('labels', 'get_collection', {
      query: query || {},
      force,
      timeout: 1_200_000, // 20 min timeout, because backend can be very slow on labels get_collection with usage
    });
  },
  // Get label by ID
  getInstance(id, usage, force) {
    return execute('labels', 'get_instance', {
      params: {
        label_id: id,
      },
      query: usage
        ? {
            usage: true,
          }
        : {},
      force,
    });
  },

  // get label type settings
  getLabelSettingsCollection(query, force) {
    return execute('label_dimensions', 'get_collection', {
      query: query || {},
      force,
    });
  },

  // Create a label
  create(data, noReset) {
    return execute('labels', 'create', {
      data,
    }).then(response => {
      if (!noReset) {
        reloadListPage('labelList');
      }

      labels.getCollection(
        {
          usage: true,
        },
        true /*force fetch*/,
      );

      return response;
    });
  },
  // Delete multiple labels
  delete(labelsArr) {
    return execute('labels', 'delete', {
      data: {
        labels: labelsArr,
      },
    }).then(response => {
      reloadListPage('labelList');
      labels.getCollection(
        {
          usage: true,
        },
        true /*force fetch*/,
      );

      return response;
    });
  },
};

// Label API
export const label = {
  // Modify a label by ID
  update(id, data) {
    return execute('label', 'update', {
      params: {
        label_id: id,
      },
      data,
    }).then(() => {
      labels.getInstance(id, true /*usage*/, true /*force fetch*/);
    });
  },
  // Delete a label by ID
  delete(id) {
    return execute('label', 'delete', {
      params: {
        label_id: id,
      },
    }).then(response => {
      reloadListPage('labelList');
      labels.getCollection(null, true /*force fetch*/);

      return response;
    });
  },
};

export const coreServices = {
  getSettings() {
    return execute('settings_core_services', 'get');
  },
};

// K/V Pairs API
export const kvPairs = {
  getInstance(userId, key) {
    return execute('kvpairs', 'get_instance', {
      params: {
        user_id: userId,
        key,
      },
    });
  },
};

export const kvPair = {
  update(userId, key, data) {
    return execute('kvpair', 'update', {
      params: {
        user_id: userId,
        key,
      },
      data,
    }).then(() => {
      kvPairs.getInstance(userId, key);
    });
  },
  delete(userId, key) {
    return execute('kvpair', 'delete', {
      params: {
        user_id: userId,
        key,
      },
    });
  },
};

// API Keys API
export const apiKeys = {
  getCollection(userId, query, force) {
    return execute('api_keys', 'get_collection', {
      params: {
        user_id: userId,
      },
      query,
      force,
    });
  },
  getInstance(userId, keyId, force) {
    return execute('api_keys', 'get_instance', {
      params: {
        user_id: userId,
        key_id: keyId,
      },
      force,
    });
  },
  create(userId, data) {
    return execute('api_keys', 'create', {
      params: {
        user_id: userId,
      },
      data,
    }).then(response => {
      apiKeys.getCollection(userId, true);

      return response;
    });
  },
};

// API Key API
export const apiKey = {
  delete(userId, keyId) {
    return execute('api_key', 'delete', {
      params: {
        user_id: userId,
        key_id: keyId,
      },
    }).then(response => {
      apiKeys.getCollection(userId, true);

      return response;
    });
  },
  update(userId, keyId, data) {
    return execute('api_key', 'update', {
      params: {
        user_id: userId,
        key_id: keyId,
      },
      data,
    }).then(response => {
      apiKeys.getCollection(userId, true);

      return response;
    });
  },
};

// Roles API
export const roles = {
  // Get the roles in the org
  getCollection(query, force) {
    return execute('roles', 'get_collection', {
      query,
      force,
    });
  },
  getInstance(roleName, force) {
    return execute('roles', 'get_instance', {
      params: {
        role_name: roleName,
      },
      force,
    });
  },
};

// Audit Log Event API
export const auditLogEvents = {
  // Get a list of events
  getCollection(query = {max_results: 1000}, force) {
    return execute('audit_log_events', 'get_collection', {
      query,
      force,
    });
  },

  //Get a list of events but with flag so that it doesn't enter store
  exportCollection(query = {max_results: 1000}, force) {
    return execute('audit_log_events', 'get_collection', {
      query,
      force,
      exportFlag: true,
    });
  },

  // Get an event by ID
  getInstance(id, query, force) {
    return execute('audit_log_events', 'get_instance', {
      params: {
        audit_log_event_id: id,
      },
      query,
      force,
    });
  },
};

// Traffic Events API
export const trafficEvents = {
  // Return the set of traffic events
  getCollection(query, force) {
    return execute('traffic_events', 'get_collection', {
      query,
      force,
    });
  },
  // Get a traffic event by ID
  getInstance(id, force) {
    return execute('traffic_events', 'get_instance', {
      params: {
        traffic_event_id: id,
      },
      force,
    });
  },
};

// Networks API
export const networks = {
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('networks', 'autocomplete', {
      query,
    });
  },
  facets(query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('networks', 'facets', {
      query,
    });
  },
  getCollection(query, force) {
    query ||= {};

    return execute('networks', 'get_collection', {
      query,
      force,
    });
  },
};

// Container Clusters API
export const containerClusters = {
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('container_clusters', 'autocomplete', {
      query,
    });
  },

  getCollection(query, force) {
    query ||= {};

    return execute('container_clusters', 'get_collection', {
      query,
      force,
    });
  },

  // Get the container cluster information
  getInstance(id, query, force) {
    return execute('container_clusters', 'get_instance', {
      params: {
        container_cluster_id: id,
      },
      query,
      force,
    });
  },
};

export const containerCluster = {
  delete({container_cluster_id}) {
    return execute('container_cluster', 'delete', {
      params: {
        container_cluster_id,
      },
    });
  },
};

// Container Workloads API
export const containerWorkloads = {
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('container_workloads', 'autocomplete', {
      query,
    });
  },
  // Workload Facets API for filtering Workloads
  facets(query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('container_workloads', 'facets', {
      query,
    });
  },
  getCollection(query, force) {
    query ||= {};

    return execute('container_workloads', 'get_collection', {
      query,
      force,
    });
  },
  // Get the workload information
  getInstance(id, query, force) {
    return execute('container_workloads', 'get_instance', {
      params: {
        container_workload_id: id,
      },
      query,
      force,
    });
  },
  getRunningContainers(id, query, force) {
    query ||= {};

    return execute('container_workload', 'running_containers', {
      params: {
        container_workload_id: id,
      },
      query,
      force,
    });
  },
};

export const containerWorkload = {
  // Update the container workload information
  update(id, data) {
    return execute('container_workload', 'update', {
      params: {
        container_workload_id: id,
      },
      data,
    }).then(() => {
      containerWorkloads.getInstance(id, true /*force fetch*/);

      actionCreators.updateForAll();
    });
  },
};

// Workloads API
export const workloads = {
  // Used in Object Selector in Rulesets page, etc
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('workloads', 'autocomplete', {
      query,
    });
  },
  // Workload Facets API for filtering Workloads
  facets(query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('workloads', 'facets', {
      query,
    });
  },
  // Get the workload information
  getCollection(query = {}, noCache, isList) {
    return cachedExecute('workloads', 'get_collection', {
      query,
      noCache,
      isList,
    });
  },
  // Get the workload information
  getInstance(id, query, force, isDetail) {
    if (!id) {
      return;
    }

    return execute('workloads', 'get_instance', {
      params: {
        workload_id: id,
      },
      query,
      force,
      isDetail,
    });
  },
  // Used to create a workload
  create(data) {
    return execute('workloads', 'create', {
      data,
    }).then(response => {
      reloadListPage('workloadList');
      // Creating workloads could move traffic from IP Lists for any scope
      actionCreators.updateForAll();

      workloads.getCollection({} /*query*/, true /*force fetch*/);

      return response;
    });
  },
  // Used to check if the current user has permissions to change the worload with given labels.
  verify_caps(data, nodeType, nodeData) {
    return execute('workloads', 'verify_caps', {
      data,
      nodeType,
      nodeData,
    }).then(response => response);
  },
  // Sent by the user to update the mode on multiple workloads
  update(data) {
    if (_.isEmpty(data)) {
      return;
    }

    const oldScopes = RuleGraphUtils.getScopesFromWorkloads(
      _.map(
        data.workloads,
        workload => WorkloadStore.getSpecified(workload.href) || TrafficStore.getNode(workload.href),
      ),
    );

    // data should include the array of workloads to update
    return execute('workloads', 'update', {
      data,
    }).then(() => {
      reloadListPage('workloadList');
      // currently this function is used for setting policy state
      // so assume no labels will be updated,
      // and thus no new scopes that we need to update for illumination
      actionCreators.updateTrafficForScopes(oldScopes);
    });
  },
  // Sent by the user to unpair multiple workloads
  unpair(data) {
    return execute('workloads', 'unpair', {
      data,
    }).then(response => {
      // Deleting workloads could move traffic to IP Lists for any scope
      actionCreators.updateForAll();
      reloadListPage('workloadList');
      workloads.getCollection({} /*query*/, true /*force fetch*/);

      return response;
    });
  },
  async delete(ids) {
    if (!Array.isArray(ids) || _.isEmpty(ids)) {
      return;
    }

    for (const id of ids) {
      await execute('workload', 'delete', {params: {workload_id: id}});
    }

    // Deleting workloads could move traffic to IP Lists for any scope
    actionCreators.updateForAll();

    reloadListPage('workloadList');
    workloads.getCollection({} /*query*/, true /*force fetch*/);
    localStorage.removeItem('saved_ip_addresses');
  },
  // Sent by the user to set the label on multiple workloads
  setLabels(workloads, labels, deleteExisting) {
    if (_.isEmpty(workloads)) {
      return;
    }

    const workloadsArr = workloads.map(w => WorkloadStore.getSpecified(w.href) || TrafficStore.getNode(w.href));
    const scopes = RuleGraphUtils.getScopesFromWorkloads(workloadsArr);
    const oldScopes = RuleGraphUtils.getScopesFromWorkloads(workloadsArr);
    let newScopes;

    if (workloads[0].labels) {
      newScopes = RuleGraphUtils.getScopesFromWorkloads(workloads);
      RuleGraphUtils.mergeScopes(scopes, newScopes);
    }

    const data = {
      workloads: workloads.map(d => ({href: d.href})),
      labels: LabelUtils.stripExtraneous(labels),
      delete_existing_keys: deleteExisting,
    };

    return execute('workloads', 'set_labels', {
      data,
    }).then(res => {
      reloadListPage('workloadList');
      actionCreators.updateTrafficForScopes(scopes);

      const reload = reloadAppGroupData(oldScopes, newScopes);

      if (reload) {
        actionCreators.updateForAll();
      }

      if (res.body[0]?.errors.length) {
        return res.body[0]?.errors;
      }
    });
  },
  // Sent by the user to remove a label from multiple workloads
  removeLabels(workloads, labelKeys) {
    if (_.isEmpty(workloads) || _.isEmpty(labelKeys)) {
      return;
    }

    const data = {
      workloads: workloads.map(d => ({href: d.href})),
      label_keys: labelKeys,
    };

    return execute('workloads', 'remove_labels', {
      data,
    }).then(() => {
      // NOTE: currently we are not using this API
      // but if we do, please let Illumination know, this will
      // impact the accuracy of the Illumination map
      reloadListPage('workloadList');
    });
  },
  // Set the flow reporting frequency for multiple workloads
  setFlowReportingFrequency(data) {
    return execute('workloads', 'set_flow_reporting_frequency', {
      data,
    });
  },
  bulkUpdate(data) {
    if (_.isEmpty(data)) {
      return;
    }

    const workloadsArr = _.map(
      data,
      workload => WorkloadStore.getSpecified(workload.href) || TrafficStore.getNode(workload.href),
    );
    const scopes = RuleGraphUtils.getScopesFromWorkloads(workloadsArr);

    if (data[0].labels) {
      const newScopes = RuleGraphUtils.getScopesFromWorkloads(data);

      RuleGraphUtils.mergeScopes(scopes, newScopes);
      // Avenger only takes label hrefs, so strip out the label key/val
      data = _.map(data, workload => {
        workload.labels = LabelUtils.stripExtraneous(workload.labels);

        return workload;
      });
    }

    return execute('workloads', 'bulk_update', {
      data,
    }).then(() => {
      reloadListPage('workloadList');
      actionCreators.updateTrafficForScopes(scopes);
    });
  },
  getPolicyModeCount({mode = 'static', maxResult = 0, force}) {
    return cachedExecute('workloads', 'get_collection', {
      query: {security_policy_update_mode: mode, max_results: maxResult},
      noCache: force,
    });
  },
  applyPolicy(data) {
    return execute('workloads', 'apply_policy', {
      data,
    }).then(() => reloadListPage('workloadList'));
  },
  bulkCreate(data) {
    if (_.isEmpty(data)) {
      return;
    }

    return execute('workloads', 'bulk_create', {
      data,
    }).then(() => {
      // Creating workloads could move traffic from IP Lists for any scope
      actionCreators.updateForAll();
      reloadListPage('workloadList');
    });
  },
};

// Service Bindings API
export const serviceBindings = {
  // Get Collection serviceBindings
  getCollection(query = {}, force) {
    return execute('service_bindings', 'get_collection', {
      query,
      force,
    }).then(response => {
      if (response && response.body) {
        response.body.forEach(fixPortOverridesServicesForClient);
      }

      return response;
    });
  },
  // Create Service Binding
  create(data) {
    data.forEach(item => {
      if (!item.port_overrides) {
        return;
      }

      item.port_overrides.forEach(fixServicePortForApi);
    });

    return execute('service_bindings', 'create', {
      data,
    });
  },
  // Get Service Binding
  getInstance(id, query = {}) {
    return execute('service_bindings', 'get_instance', {
      params: {
        service_binding_id: id,
      },
      query,
    }).then(response => {
      if (response && response.body) {
        fixPortOverridesServicesForClient(response.body);
      }

      return response;
    });
  },
};

// Service Binding API
export const serviceBinding = {
  // Delete Service Binding
  delete(id) {
    return execute('service_binding', 'delete', {
      params: {
        service_binding_id: id,
      },
    });
  },
};

// Workload API
export const workload = {
  // Update the workload information
  update(id, data, leaveTraffic) {
    const href = getSessionUri(getInstanceUri('workloads'), {workload_id: id});
    const workload = WorkloadStore.getSpecified(href) || TrafficStore.getNode(href);
    const scopes = RuleGraphUtils.getScopesFromWorkload(workload);
    const oldScopes = RuleGraphUtils.getScopesFromWorkload(workload);
    let newScopes;

    if (data.labels) {
      // if we're updating workload's labels, get the new scopes from those also
      newScopes = RuleGraphUtils.getScopesFromWorkload(data);

      RuleGraphUtils.mergeScopes(scopes, newScopes);

      data.labels = LabelUtils.stripExtraneous(data.labels);
    }

    return execute('workload', 'update', {
      params: {
        workload_id: id,
      },
      data,
    }).then(() => {
      workloads.getInstance(
        id,
        {} /*query*/,
        true /*force fetch*/,
        true /* update the workload after update api request */,
      );

      // Only if the app, label or env scope label changes update the appgroup map data.
      if (newScopes && newScopes[0]) {
        // Verify each label exists before testing the equivalency
        if (
          ['app', 'env', 'loc'].some(
            label =>
              (newScopes[0][label] && newScopes[0][label].href) !== (oldScopes[0][label] && oldScopes[0][label].href),
          )
        ) {
          actionCreators.updateForAll();
        }
      }

      if (!leaveTraffic) {
        actionCreators.updateTrafficForScopes(scopes);
      }
    });
  },
  // Delete the workload record
  delete(id) {
    return execute('workload', 'delete', {
      params: {
        workload_id: id,
      },
    }).then(response => {
      // Deleting workloads could move traffic to IP Lists for any scope
      actionCreators.updateForAll();

      localStorage.removeItem('saved_ip_addresses');
      workloads.getCollection({} /*query*/, true /*force fetch*/);

      return response;
    });
  },
  // Sent by the user to upgrade an workload to the latest version
  upgrade(id) {
    return execute('workload', 'upgrade', {
      params: {
        workload_id: id,
      },
    });
  },
  // Redetect the network for this workload
  redetectNetwork(id) {
    return execute('workload', 'redetect_network', {
      params: {
        workload_id: id,
      },
    });
  },
  serviceReports(id, reports) {
    return execute('workload', 'service_reports', {
      params: {
        workload_id: id,
      },
      data: reports,
    }).then(() => {
      workloads.getInstance(id, {} /*query*/, true /*force fetch*/);
    });
  },
  // Get the latest service report
  serviceReportsLatest(id) {
    return execute('workload', 'service_reports_latest', {
      params: {
        workload_id: id,
      },
    });
  },
};

// IP Lists API
export const ipLists = {
  // Used in Object Selector in Rulesets page, etc
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('ip_lists', 'autocomplete', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
    });
  },
  // IP Lists Facets API for filtering IP Lists
  facets(query, pversion) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('ip_lists', 'facets', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
    });
  },
  // Get the collection of IP lists
  getCollection(query, pversion, force) {
    return execute('ip_lists', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
      force,
    });
  },
  // Get IP list by ID
  getInstance(id, pversion, force) {
    return execute('ip_lists', 'get_instance', {
      params: {
        ip_list_id: id,
        pversion: pversion || 'draft',
      },
      force,
    });
  },
  // Create new IP List
  create(data, pversion, skipTraffic) {
    return execute('ip_lists', 'create', {
      params: {
        pversion: pversion || 'draft',
      },
      data,
    })
      .then(response => {
        reloadListPage('ipListList');
        ipLists.getCollection({} /*query*/, 'draft', true /*force fetch*/);

        return response;
      })
      .then(response => {
        if (!skipTraffic) {
          // when an ipList's address range is updated, we would have to
          // re-render the whole graph - expensive ):
          actionCreators.updateForAll();
        }

        return response;
      });
  },
  // Delete multiple IP Lists
  delete(ipListsArr, pversion) {
    return execute('ip_lists', 'delete', {
      params: {
        pversion: pversion || 'draft',
      },
      data: {
        ip_lists: ipListsArr,
      },
    })
      .then(response => {
        reloadListPage('ipListList');
        ipLists.getCollection({} /*query*/, 'draft', true /*force fetch*/);

        return response;
      })
      .then(response => {
        // when an ipList's address range is updated, we would have to
        // re-render the whole graph - expensive ):
        actionCreators.updateForAll();

        return response;
      });
  },
};

// IP List API
export const ipList = {
  // Modify IP list
  update(id, data, pversion) {
    const href = getSessionUri(getInstanceUri('ip_lists'), {
      ip_list_id: id,
      pversion: pversion || 'draft',
    });
    const ipList = IpListStore.getSpecified(href);

    if (
      ipList &&
      ipList.external_data_set === 'illumio_segmentation_templates' &&
      !data.external_data_reference &&
      DataValidationUtils.ipLists.hasChanged(data, ipList, true)
    ) {
      const dataRef = DataValidationUtils.dataReference.formatEditedState(ipList.external_data_reference);

      data.external_data_reference = dataRef;
    }

    return execute('ip_list', 'update', {
      params: {
        ip_list_id: id,
        pversion: pversion || 'draft',
      },
      data,
    })
      .then(() => {
        reloadListPage('ipListList');
        ipLists.getInstance(id, 'draft', true /*force fetch*/);
      })
      .then(response => {
        // when an ipList's address range is updated, we would have to
        // re-render the whole graph - expensive ):
        actionCreators.updateForAll();

        return response;
      });
  },
  // Delete IP list
  delete(id, pversion) {
    return execute('ip_list', 'delete', {
      params: {
        ip_list_id: id,
        pversion: pversion || 'draft',
      },
    })
      .then(response => {
        reloadListPage('ipListList');
        ipLists.getCollection({} /*query*/, 'draft', true /*force fetch*/);

        return response;
      })
      .then(response => {
        // when an ipList's address range is updated, we would have to
        // re-render the whole graph - expensive ):
        actionCreators.updateForAll();

        return response;
      });
  },
};

// Pairing Profiles API
export const pairingProfiles = {
  // Pairing Profiles Facets API for filtering Pairing Profiles
  facets(query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('pairing_profiles', 'facets', {
      query,
    });
  },
  // Return the set of pairing profiles
  getCollection(query, force, groupLabels = false) {
    return execute('pairing_profiles', 'get_collection', {
      query,
      force,
      groupLabels,
    });
  },
  // Get pairing profile by ID
  getInstance(query, id, force) {
    return execute('pairing_profiles', 'get_instance', {
      params: {
        pairing_profile_id: id,
      },
      force,
      query,
    });
  },
  // Create a pairing profile
  create(data) {
    return execute('pairing_profiles', 'create', {
      data,
    }).then(response => {
      reloadListPage('pairingProfileList');
      pairingProfiles.getCollection({representation: 'pairing_profile_labels'}, true);

      return response;
    });
  },
  // Delete multiple pairing profiles
  delete(profiles) {
    return execute('pairing_profiles', 'delete', {
      data: {
        profiles,
      },
    }).then(response => {
      reloadListPage('pairingProfileList');
      pairingProfiles.getCollection({representation: 'pairing_profile_labels'} /*query*/, true /*force fetch*/);

      return response;
    });
  },
};

// Pairing Profile API
export const pairingProfile = {
  // Modify a pairing profile by ID
  update(id, data) {
    return execute('pairing_profile', 'update', {
      params: {
        pairing_profile_id: id,
      },
      data,
    }).then(() => {
      pairingProfiles.getInstance(
        {
          representation: 'pairing_profile_labels',
        },
        id,
        true /*force fetch*/,
      );
    });
  },
  // Generate a pairing key for a pairing profile by ID
  pairingKey(id, data) {
    return execute('pairing_profile', 'pairing_key', {
      params: {
        pairing_profile_id: id,
      },
      data,
    });
  },
  // Delete a pairing profile by ID
  delete(id) {
    return execute('pairing_profile', 'delete', {
      params: {
        pairing_profile_id: id,
      },
    }).then(response => {
      reloadListPage('pairingProfileList');
      pairingProfiles.getCollection({} /*query*/, true /*force fetch*/);

      return response;
    });
  },
};

// Firewall Settings API
export const firewallSettings = {
  // Get the firewall settings object
  get(query = {}, pversion, noCache) {
    return cachedExecute('firewall_settings', 'get', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
      noCache,
    });
  },
  // Modify firewall settings object
  update(data, pversion) {
    return execute('firewall_settings', 'update', {
      params: {
        pversion: pversion || 'draft',
      },
      data,
    }).then(response => {
      firewallSettings.get({representation: 'scope_details'}, pversion, true /*force fetch*/);

      return response;
    });
  },
};

// Sec Policy Graph API
export const secPolicyGraph = {
  get(query, iterate, pversion, force) {
    return execute('sec_policy_graph', 'get', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
      iterate,
      timeout: 600_000,
      force,
    });
  },
};

export const vulnerabilityReports = {
  getCollection(query, force) {
    return execute('vulnerability_reports', 'get_collection', {
      query,
      force,
    });
  },
};

export const vulnerabilityInstances = {
  getAggregateVulnerabilityInstances(labels, node) {
    return execute('aggregated_detected_vulnerabilities', 'get_collection', {
      query: {workload_labels: labels},
      node,
    });
  },

  getVulnerabilityInstances(node) {
    return execute('detected_vulnerabilities', 'get_collection', {
      params: {workload_id: node.split('/').pop()},
      node,
    });
  },

  async iterate(type, nodes) {
    const nodesToIterate = nodes.length;

    if (nodesToIterate) {
      // For the spinner
      actionCreators.ruleCoverageIterateStart();

      return new Promise(resolve => {
        nodes.forEach(async node => {
          try {
            if (type === 'workloads') {
              await vulnerabilityInstances.getVulnerabilityInstances(node);
            } else {
              let labels = TrafficStore.getNode(node).labels.map(label => label.href);

              if (type === 'roles') {
                // For roles, specifically ask for the missing labels to be missing.
                const hrefPrefix = getSessionUri(getInstanceUri('labels'), {label_id: ''}).slice(0, -1);

                const labelsObj = {
                  role: `${hrefPrefix}?key=role&exists=false`,
                  app: `${hrefPrefix}?key=app&exists=false`,
                  env: `${hrefPrefix}?key=env&exists=false`,
                  loc: `${hrefPrefix}?key=loc&exists=false`,
                };

                TrafficStore.getNode(node).labels.forEach(label => {
                  labelsObj[label.key] = label.href;
                });

                labels = Object.values(labelsObj);
              }

              await vulnerabilityInstances.getAggregateVulnerabilityInstances(JSON.stringify(labels), node);
            }
          } catch {
            return;
          }
        });

        actionCreators.ruleCoverageIterateComplete();
        resolve();
      });
    }
  },
};

export const ruleCoverage = {
  get(data, hrefs, type, href, pversion, force) {
    const options = {
      params: {
        pversion: pversion || 'draft',
      },
      timeout: 600_000,
      force,
      data,
      trafficHrefs: hrefs,
      type,
      href,
      query: {include_deny_rules: true},
    };

    return execute('sec_policy_rule_coverage', 'create', options);
  },

  async iterate(links, ver = 'draft') {
    const linksToIterate = links.length;

    if (linksToIterate) {
      const chunkSize = 100;
      const limit = pLimit(4);

      // For the spinner
      actionCreators.ruleCoverageIterateStart();

      const openRequests = [];

      const {trafficsForRuleCoverage, trafficHrefs} = TrafficStore.getTrafficForRuleCoverage(links);
      const trafficsForRuleCoverageSets = _.chunk(trafficsForRuleCoverage, chunkSize);
      const trafficHrefSets = _.chunk(trafficHrefs, chunkSize);

      trafficsForRuleCoverageSets.forEach((trafficForRuleCoverage, i) => {
        openRequests.push(
          limit(() => ruleCoverage.get(trafficForRuleCoverage, trafficHrefSets[i], 'illumination', null, ver)),
        );
      });

      await Promise.all(openRequests);

      actionCreators.ruleCoverageIterateComplete();
    }
  },
};

export const trafficFlows = {
  async: {
    getCollection(force) {
      return execute('traffic_flows_async_queries', 'get_collection', {force});
    },

    getInstance(uuid, force) {
      return execute('traffic_flows_async_queries', 'get_instance', {
        params: {uuid},
        force,
      });
    },

    create(data, force) {
      return execute('traffic_flows_async_queries', 'create', {
        force,
        data,
      });
    },

    getResults(id, options) {
      return execute('traffic_flows_async_queries', 'download', {
        params: {
          uuid: id,
        },
        query: {
          limit: UserStore.getExplorerMaxResults(),
          offset: 0,
        },
        timeout: 1_500_000,
        ...options,
      });
    },

    delete(uuid) {
      return execute('traffic_flows_async_queries', 'delete', {
        params: {uuid},
        timeout: 60_000,
        force: true,
      });
    },

    cancel(uuid) {
      return execute('traffic_flows_async_queries', 'update', {
        params: {uuid},
        data: {status: 'cancel_requested'},
        timeout: 60_000,
        force: true,
      });
    },
  },

  get(data, force, type, href, labels, allWorkloads, concatenate) {
    return execute('traffic_flows', 'traffic_analysis_queries', {
      timeout: 1_500_000,
      force,
      data,
      type,
      href,
      labels,
      allWorkloads,
      concatenate,
    });
  },
};

let appGroupRuleTimer;
let appGroupRuleForGroupTimer;

// App Group Rule Coverage
export const appGroupRules = {
  getCollection() {
    return execute('app_groups', 'observed_rule_coverage', {
      force: true,
    });
  },

  get(appGroup, force) {
    return execute('app_group', 'observed_rule_coverage', {
      params: {
        app_group_id: appGroup,
      },
      force,
    });
  },

  update(appGroup, force) {
    if (isAPIAvailable('app_groups.observed_rule_coverage')) {
      return execute('app_group', 'observed_rule_coverage', {
        params: {
          app_group_id: appGroup,
        },
        force,
        headers: {'cache-control': 'no-cache'},
      });
    }
  },

  startAppGroupRulesTimer() {
    if (appGroupRuleTimer) {
      clearTimeout(appGroupRuleTimer);
      appGroupRuleTimer = 0;
    }

    if (isAPIAvailable('app_groups.observed_rule_coverage')) {
      appGroupRules.getCollection().catch(() => {
        appGroupRuleTimer = setTimeout(() => {
          appGroupRules.startAppGroupRulesTimer();
        }, 60_000);
      });
    }
  },

  startAppGroupRulesForGroupTimer(appGroup) {
    if (appGroupRuleForGroupTimer) {
      clearTimeout(appGroupRuleForGroupTimer);
      appGroupRuleForGroupTimer = 0;
    }

    if (isAPIAvailable('app_groups.observed_rule_coverage')) {
      appGroupRules.get(appGroup).catch(() => {
        appGroupRuleForGroupTimer = setTimeout(() => {
          appGroupRules.startAppGroupRulesForGroupTimer(appGroup);
        }, 60_000);
      });
    }
  },
};

// Agent Traffic API
export const agentTraffic = {
  // Return workload traffic data to UI
  async getRebuild(query, timeout = 60_000, clearTraffic) {
    if (!SessionStore.isIlluminationApiEnabled()) {
      return;
    }

    const maxLists = parseInt(localStorage.getItem('overlapping_iplists'), 10) || 50;
    const maxPorts = query.max_ports || parseInt(localStorage.getItem('max_ports'), 10) || 5000;
    const maxNodes = query.max_nodes || parseInt(localStorage.getItem('truncation_limit'), 10) || 5000;

    const response = await execute('network_traffic', 'get', {
      query: {...query, max_ports: maxPorts, max_ip_lists: maxLists, max_nodes: maxNodes},
      timeout,
      headers: {'cache-control': 'no-cache'},
      clearTraffic,
    });

    return response;
  },

  async getCache(includeStale, query, timeout = 5000, clearTraffic) {
    if (!SessionStore.isIlluminationApiEnabled()) {
      return;
    }

    const maxLists = parseInt(localStorage.getItem('overlapping_iplists'), 10) || 50;
    const maxPorts = query.max_ports || parseInt(localStorage.getItem('max_ports'), 10) || 5000;
    const maxNodes = query.max_nodes || parseInt(localStorage.getItem('truncation_limit'), 10) || 5000;

    // Make a quick request
    const response = await execute('network_traffic', 'get', {
      query: {...query, include_stale: includeStale, max_ports: maxPorts, max_ip_lists: maxLists, max_nodes: maxNodes},
      timeout,
      clearTraffic,
    });

    return response;
  },

  // Clear traffic for specified org and (sub)graph
  clear(data) {
    if (!SessionStore.isIlluminationApiEnabled()) {
      return;
    }

    return execute('network_traffic', 'update', {
      data,
    });
  },

  // Get caps for a group
  caps(type, data) {
    if (!SessionStore.isIlluminationApiEnabled()) {
      return;
    }

    return execute('network_traffic', 'caps', {
      query: {[type]: data},
    });
  },

  // Delete traffic for workloads in specified org assigned all specified labels
  delete(query) {
    if (!SessionStore.isIlluminationApiEnabled()) {
      return;
    }

    return execute('network_traffic', 'delete', {
      query,
    });
  },

  getGroupLink(group, link) {
    if (!SessionStore.isIlluminationApiEnabled()) {
      return;
    }

    const policyStates = _.transform(
      TrafficFilterStore.getPolicyStates(),
      (result, value, policyState) => {
        if (!value) {
          result.push(policyState);
        }
      },
      [],
    );

    const exclude = JSON.stringify(policyStates);

    // Get the labels for the cluster endpoints
    let labels = _.map(
      link.split(','),
      group => TrafficStore.getNode(group) && _.map(TrafficStore.getNode(group).labels, label => label.href),
    );

    // Ensure we found two valid groups
    if (_.some(labels, labelSet => !labelSet || !labelSet.length)) {
      return;
    }

    labels = JSON.stringify(labels);

    const query = {
      max_ports: localStorage.getItem('max_ports') || 64,
      include_private: false,
      labels,
      exclude,
    };

    query.clusters = true;
    query.cluster_keys = JSON.stringify([group]);

    // Limit the number of overlapping IP Lists for both simple and full traffic
    query.max_ip_lists = parseInt(localStorage.getItem('overlapping_iplists'), 10) || 50;

    return execute('network_traffic', 'get', {
      params: {
        pversion: 'draft',
      },
      query,
      option: {
        href: link,
      },
      timeout: 600_000,
      force: true,
      isSingleClusterLink: true,
    });
  },

  async iterate(query) {
    if (!SessionStore.isIlluminationApiEnabled()) {
      return;
    }

    const {type, nodes, privateIps, maxPorts, pversion = 'draft', labels = '[]', exclude = '[]'} = query;

    const params = {
      max_ports: maxPorts || localStorage.getItem('max_ports') || 64,
      include_private: privateIps || false,
      labels,
      exclude,
    };

    const results = [];

    // For the spinner
    actionCreators.networkTrafficIterateStart();

    for (const nodesChunk of _.chunk(nodes, 15)) {
      const nextNodes = JSON.stringify(nodesChunk);

      if (type === 'workloads') {
        params.workloads = nextNodes;
      } else if (type === 'roles') {
        params.roles = true;
        params.role_keys = nextNodes;
      } else if (type === 'groups') {
        params.clusters = true;
        params.cluster_keys = nextNodes;
      }

      try {
        const result = await agentTraffic.get(params, pversion);

        results.push(result);
      } catch {
        actionCreators.networkTrafficIterateComplete();

        return results;
      }
    }

    actionCreators.networkTrafficIterateComplete();

    return results;
  },

  update_subgraphs() {
    return execute('network_traffic', 'update_subgraphs', {
      timeout: 600_000,
      force: true,
    }).then(() => actionCreators.updateForAll());
  },
};

export const enforcementBoundaries = {
  getCollection(pversion, force, query) {
    return execute('enforcement_boundaries', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
      },
      force,
      query: query || {},
    });
  },
  get(id, pversion, force, query) {
    return execute('enforcement_boundaries', 'get_instance', {
      params: {
        pversion: pversion || 'draft',
        enforcement_boundary_id: id,
      },
      force,
      query: query || {},
    });
  },
  delete(id, pversion) {
    return execute('enforcement_boundary', 'delete', {
      params: {enforcement_boundary_id: id, pversion: pversion || 'draft'},
    });
  },
};

// Management Ports API
export const managementPorts = {
  // Return the array of management ports
  getCollection(force) {
    return execute('management_ports', 'get_collection', {
      force,
    });
  },
};

// Workload Os Families API
export const workloadOsFamilies = {
  // Return the array of workload_os_families
  getCollection(force, query) {
    return execute('workload_os_families', 'get_collection', {
      force,
      query,
    });
  },
};

// Agent Support Report Requests API
export const agentSupportReportRequests = {
  // Return the array of agent_support_report_requests
  getCollection(force, query) {
    return execute('agent_support_report_requests', 'get_collection', {
      query: query || {},
      force,
    });
  },
  // Generate support report for an agent
  create(href) {
    return execute('agent_support_report_requests', 'create', {
      data: {
        workload: {href},
      },
    });
  },
};

// System Management API
export const systemManagement = {
  details: {
    get(force) {
      return execute('config', 'get', {
        force,
      });
    },

    update(data) {
      return execute('config', 'update', {
        data,
      }).then(response => {
        systemManagement.details.get(true /*force fetch*/);

        return response;
      });
    },
  },

  logging: {
    fluentd: {
      get(force) {
        return execute('fluentd_config', 'get', {
          force,
        });
      },
      update(data) {
        return execute('fluentd_config', 'update', {
          data,
        }).then(response => {
          systemManagement.logging.fluentd.get(true /*force fetch*/);

          return response;
        });
      },
    },
    syslog: {
      get(force) {
        return execute('syslog', 'get', {
          force,
        });
      },
      update(data) {
        return execute('syslog', 'update', {
          data,
        }).then(response => {
          systemManagement.logging.syslog.get(true /*force fetch*/);

          return response;
        });
      },
    },
  },

  networkInterfaces: {
    getCollection(force) {
      return execute('network_interfaces', 'get_collection', {
        force,
      });
    },

    apply() {
      return execute('network_interfaces', 'apply').then(response => {
        systemManagement.networkInterfaces.getCollection(true /*force fetch*/);

        return response;
      });
    },

    discard() {
      return execute('network_interfaces', 'discard').then(response => {
        systemManagement.networkInterfaces.getCollection(true /*force fetch*/);

        return response;
      });
    },

    update(name, data) {
      return execute('network_interface', 'update', {
        params: {
          iface: name,
        },
        data,
      });
    },
  },

  email: {
    get(force) {
      return execute('email', 'get', {
        force,
      });
    },

    update(data) {
      return execute('email', 'update', {
        data,
      }).then(response => {
        systemManagement.email.get(true /*force fetch*/);

        return response;
      });
    },

    test(email) {
      return execute('email', 'test', {
        data: {
          email_addr: email,
        },
      });
    },
  },

  supportReports: {
    getCollection(force) {
      return execute('support_report_requests', 'get_collection', {
        force,
      });
    },

    create() {
      return execute('support_report_requests', 'create').then(response => {
        systemManagement.supportReports.getCollection(true /*force fetch*/);

        return response;
      });
    },
  },
};

export const agents = {
  getInstance(id, force) {
    return execute('agents', 'get_instance', {
      params: {
        agent_id: id,
      },
      force,
    });
  },
};

export const provision = {
  // Get current pending provisioning items
  get(force) {
    return execute('sec_policy_pending', 'get', {
      force,
    });
  },

  inProgress() {
    return execute('dashboards', 'rule_calc_progress');
  },

  // Revert current draft provisioning
  revert(note) {
    return execute('sec_policy_pending', 'delete', {
      data: {
        commit_message: note,
      },
    }).then(() => {
      actionCreators.updateTrafficForAll();
    });
  },
};

export const nfcs = {
  // Get the network function controls
  getCollection(force) {
    return execute('nfcs', 'get_collection', {
      force,
    });
  },
};

export const slbs = {
  getCollection(force) {
    const loadBalancerEnabled = !SessionStore.isUserScoped() && !SessionStore.getClusterType();
    const cachedResponse = loadBalancerEnabled ? undefined : [];
    const preventDispatch = !loadBalancerEnabled;
    const forceIfPossible = force && loadBalancerEnabled;

    return execute(
      'slbs',
      'get_collection',
      {
        force: forceIfPossible,
      },
      preventDispatch,
      cachedResponse,
    );
  },
  getInstance(id) {
    return execute('slbs', 'get_instance', {
      params: {
        slb_id: id,
      },
    }).then(() => slbs.getCollection());
  },
  create(data) {
    return execute('slbs', 'create', {
      data,
    }).then(() => {
      slbs.getCollection(true);
    });
  },
  update(id, data) {
    return execute('slb', 'update', {
      params: {
        slb_id: id,
      },
      data,
    }).then(() => {
      slbs.getCollection(true);
    });
  },
  delete(id) {
    return execute('slb', 'delete', {
      params: {
        slb_id: id,
      },
    }).then(() => {
      slbs.getCollection(true);
    });
  },
};

// Virtual Servers API
export const virtualServers = {
  // Used in Object Selector in Rulesets page, etc
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('virtual_servers', 'autocomplete', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
    });
  },
  getCollection(pversion, query, force) {
    return execute('virtual_servers', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
      },
      query: query || {},
      force,
    });
  },
  get(id, pversion, query, force) {
    return execute('virtual_servers', 'get_instance', {
      params: {
        virtual_server_id: id,
        pversion: pversion || 'draft',
      },
      query: query || {},
      force,
    });
  },
  // Create new Virtual Server
  create(data, pversion) {
    return execute('virtual_servers', 'create', {
      params: {
        pversion: pversion || 'draft',
      },
      data,
    }).then(() => {
      secPolicies.dependencies();
      virtualServers.getCollection(pversion, {}, true);
    });
  },
};

// Virtual Server API
export const virtualServer = {
  // Modify Virtual Server
  update(id, data, pversion, labels, leaveTraffic) {
    let newScopes;
    let oldScopes;
    const vsdata = _.cloneDeep(data);

    // for dragging/dropping virtual servers
    if (labels && labels.labels) {
      if (_.isEmpty(labels.labels)) {
        vsdata.labels = [];
      } else {
        newScopes = RenderUtils.getLabels(labels.labels);
        oldScopes = labels.oldLabels;
        vsdata.labels = LabelUtils.stripExtraneous(labels.labels);
      }
    }

    return execute('virtual_server', 'update', {
      params: {
        virtual_server_id: id,
        pversion: pversion || 'draft',
      },
      data: vsdata,
    }).then(() => {
      secPolicies.dependencies();
      virtualServers.get(
        id,
        pversion,
        {
          representation: 'virtual_server_provider_names_and_labels',
        },
        true,
      );

      if (labels) {
        const scopes = [oldScopes, newScopes];

        if (!leaveTraffic) {
          actionCreators.updateTrafficForScopes(scopes);
        }
      }
    });
  },
  // Delete Virtual Server
  delete(id, pversion) {
    return execute('virtual_server', 'delete', {
      params: {
        virtual_server_id: id,
        pversion: pversion || 'draft',
      },
    }).then(() => {
      secPolicies.dependencies();
      virtualServers.getCollection(
        pversion,
        {
          representation: 'virtual_server_provider_names_and_labels',
        },
        true,
      );
    });
  },
  // Sec Policy Rule View API
  ruleView: {
    // Get a flattened view of the rules applying to a virtual server
    get(virtualServer, pversion) {
      return execute('sec_policy_rule_view', 'get', {
        params: {
          pversion: pversion || 'draft',
        },
        query: {
          virtual_server: virtualServer,
        },
      });
    },
  },
};

export const discoveredVirtualServers = {
  getCollection() {
    return execute('discovered_virtual_servers', 'get_collection');
  },
  getInstance(id) {
    return execute('discovered_virtual_servers', 'get_instance', {
      params: {
        discovered_virtual_server_id: id,
      },
    });
  },
};

// Virtual Services API
export const virtualServices = {
  getCollection(pversion, query, force) {
    query.query ||= '';
    query.max_results ||= 50;

    return execute('virtual_services', 'get_collection', {
      query,
      params: {pversion},
      force,
    });
  },
  // Used in Object Selector in Rulesets page, etc
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('virtual_services', 'autocomplete', {
      query,
      params: {pversion},
    });
  },
  // Virtual Services Facets API for filtering Virtual Services
  facets(query, pversion = 'draft') {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('virtual_services', 'facets', {
      query,
      params: {pversion},
    });
  },
  // Create Virtual Service
  create(data) {
    return execute('virtual_services', 'create', {
      data,
      params: {pversion: 'draft'},
    }).then(response => {
      actionCreators.addResource(Constants.VIRTUAL_SERVICE_STORE_ADD, response.body);

      return response;
    });
  },
  // Get Virtual Service
  getInstance(id, pversion, force) {
    return execute('virtual_services', 'get_instance', {
      params: {
        pversion,
        virtual_service_id: id,
      },
      query: {representation: 'virtual_service_labels_services_and_workloads'},
      force,
    }).then(response => {
      if (response && response.body && response.body.service) {
        fixServiceForClient(response.body.service);
      }

      return response;
    });
  },

  bulkCreate(data) {
    if (_.isEmpty(data)) {
      return;
    }

    return execute('virtual_services', 'bulk_create', {
      data,
      params: {pversion: 'draft'},
    }).then(() => {
      reloadListPage('virtualServiceList');
    });
  },
};

export const virtualService = {
  // Modify VirtualService
  update(id, data, pversion) {
    return execute('virtual_service', 'update', {
      params: {
        virtual_service_id: id,
        pversion,
      },
      data,
    }).then(() => {
      reloadListPage('virtualServiceList');
      virtualServices.getInstance(id, 'draft', true);
    });
  },
  // Delete Virtual Service
  delete(href) {
    const id = GridDataUtils.getIdFromHref(href);

    return execute('virtual_service', 'delete', {
      params: {
        virtual_service_id: id,
        pversion: 'draft',
      },
    }).then(response => {
      reloadListPage('virtualServiceList');
      virtualServices.getInstance(id, 'draft', true).catch(err => {
        if (err.status === 404) {
          // 404 means resource deleted
          actionCreators.deleteResource(Constants.VIRTUAL_SERVICE_STORE_DELETE, href);
        }
      });

      return response;
    });
  },
};

// Label Groups API
export const labelGroups = {
  // Label Groups Facets API for filtering Label Groups
  facets(query, pversion) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('label_groups', 'facets', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
    });
  },
  // Used in Object Selector in Rulesets page, etc
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('label_groups', 'autocomplete', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
    });
  },
  // Get the collection of Label Groups
  getCollection(query, pversion, force) {
    return execute('label_groups', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
      },
      query: query || {},
      force,
    });
  },
  // Get Service by id
  getInstance(id, query, pversion, force) {
    return execute('label_groups', 'get_instance', {
      params: {
        label_group_id: id,
        pversion: pversion || 'draft',
      },
      query,
      force,
    });
  },
  // Create new Service
  create(data, pversion) {
    return execute('label_groups', 'create', {
      params: {
        pversion: pversion || 'draft',
      },
      data,
    });
  },
};

// Service API
export const labelGroup = {
  // Modify Label Group
  update(id, data, pversion) {
    const href = getSessionUri(getInstanceUri('label_groups'), {
      pversion: pversion || 'draft',
      label_group_id: id,
    });
    const labelGroup = LabelGroupStore.getSpecified(href);

    if (
      labelGroup.external_data_set === 'illumio_segmentation_templates' &&
      !data.external_data_reference &&
      (!data.name || labelGroup.description !== data.description)
    ) {
      const dataRef = DataValidationUtils.dataReference.formatEditedState(labelGroup.external_data_reference);

      data.external_data_reference = dataRef;
    }

    return execute('label_group', 'update', {
      params: {
        label_group_id: id,
        pversion: pversion || 'draft',
      },
      data,
    }).then(() => {
      reloadListPage('labelGroupList');
      labelGroups.getCollection({usage: true}, 'draft', true /*force fetch*/);
    });
  },
  // Delete Label Group
  delete(id, pversion) {
    return execute('label_group', 'delete', {
      params: {
        label_group_id: id,
        pversion: pversion || 'draft',
      },
    }).then(response => {
      reloadListPage('labelGroupList');
      labelGroups.getCollection({usage: true}, 'draft', true /*force fetch*/);

      return response;
    });
  },
  memberOf(id, pversion) {
    return execute('label_group', 'member_of', {
      params: {
        label_group_id: id,
        pversion: pversion || 'draft',
      },
    });
  },

  getAllLabels(id) {
    return execute('label_group', 'all_labels', {
      params: {
        label_group_id: id,
        pversion: 'draft',
      },
    });
  },
};

export const locationSummary = {
  get(query) {
    return execute('location_summary', 'get', {
      query,
    });
  },

  async getCache(includeStale, timeout = 60_000) {
    // Make a quick request
    const response = await execute('location_summary', 'get', {
      query: {include_stale: includeStale},
      timeout,
    });

    return response;
  },

  async getRebuild(timeout = 60_000) {
    const response = await execute('location_summary', 'get', {
      query: {include_stale: false},
      timeout,
      headers: {'cache-control': 'no-cache'},
    });

    return response;
  },
};

export const appGroupSummary = {
  get(query) {
    return execute('app_group_summary', 'get', {
      query,
    });
  },

  async getCache(includeStale, timeout = 60_000) {
    // Make a quick request
    const response = await execute('app_group_summary', 'get', {
      query: {include_stale: includeStale},
      timeout,
    });

    return response;
  },

  async getRebuild(timeout = 60_000) {
    const response = await execute('app_group_summary', 'get', {
      query: {include_stale: false},
      timeout,
      headers: {'cache-control': 'no-cache'},
    });

    return response;
  },
};

export const appGroups = {
  getCollection(pversion) {
    return execute('app_groups', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
      },
      query: {
        max_results: 100_000,
      },
      timeout: 1_500_000,
    });
  },
};

export const secureConnectGateways = {
  getCollection(query, pversion, force) {
    return execute('secure_connect_gateways', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
      },
      query: query || {},
      force,
    });
  },
  create(data, pversion) {
    return execute('secure_connect_gateways', 'create', {
      params: {
        pversion: pversion || 'draft',
      },
      data,
    }).then(response => {
      secureConnectGateways.getCollection({}, 'draft', true);

      return response;
    });
  },
  getInstance(id, pversion, force) {
    return execute('secure_connect_gateways', 'get_instance', {
      params: {
        secure_connect_gateway_id: id,
        pversion: pversion || 'draft',
      },
      force,
    });
  },
};

export const secureConnectGateway = {
  update(id, data, pversion) {
    return execute('secure_connect_gateway', 'update', {
      params: {
        secure_connect_gateway_id: id,
        pversion: pversion || 'draft',
      },
      data,
    }).then(() => {
      secureConnectGateways.getCollection({}, 'draft', true);
    });
  },
  delete(id, pversion) {
    return execute('secure_connect_gateway', 'delete', {
      params: {
        secure_connect_gateway_id: id,
        pversion: pversion || 'draft',
      },
    }).then(response => {
      secureConnectGateways.getCollection({}, 'draft', true);

      return response;
    });
  },
};

// IP Table Rules APIs
export const ipTablesRules = {
  // Get the collection of IP Table Rules
  getCollection(rulesetId, query, pversion, force) {
    return execute('ip_tables_rules', 'get_collection', {
      params: {
        pversion: pversion || 'draft',
        rule_set_id: rulesetId,
      },
      query: query || {},
      force,
    });
  },
  // Get IP Table Rule by ID
  getInstance(rulesetId, id, pversion, force) {
    return execute('ip_tables_rules', 'get_instance', {
      params: {
        ip_tables_rule_id: id,
        rule_set_id: rulesetId,
        pversion: pversion || 'draft',
      },
      force,
    });
  },
  // Create new IP Table Rule
  create(rulesetId, data, pversion) {
    return execute('ip_tables_rules', 'create', {
      params: {
        rule_set_id: rulesetId,
        pversion: pversion || 'draft',
      },
      data,
    }).then(response => {
      ruleSets.getInstance(rulesetId, 'draft', true /*force fetch*/);
      secPolicies.dependencies();

      return response;
    });
  },
};

export const ipTablesRule = {
  // Modify IP Table Rule
  update(rulesetId, id, data) {
    return execute('ip_tables_rule', 'update', {
      params: {
        rule_set_id: rulesetId,
        ip_tables_rule_id: id,
        pversion: 'draft',
      },
      data,
    })
      .then(() => ipTablesRules.getInstance(rulesetId, id, 'draft', true /*force fetch*/))
      .then(response => {
        ruleSets.getInstance(rulesetId, 'draft', true /*force fetch*/);
        secPolicies.dependencies();

        return response;
      });
  },
  // Delete IP Table Rule
  delete(rulesetId, id, pversion) {
    return execute('ip_tables_rule', 'delete', {
      params: {
        rule_set_id: rulesetId,
        ip_tables_rule_id: id,
        pversion: pversion || 'draft',
      },
    }).then(response => {
      ruleSets.getInstance(rulesetId, 'draft', true /*force fetch*/);
      secPolicies.dependencies();

      return response;
    });
  },
};

// VEN agent releases currently available to org
export const venReleases = {
  // Get the collection of VEN releases
  getCollection() {
    if (SessionStore.isUserScoped()) {
      return Promise.resolve();
    }

    return execute('ven_software_releases', 'get_collection', {query: {representation: 'expanded_images'}});
  },
};

// Vens API
export const vens = {
  autocomplete(pversion, query) {
    query.query ||= '';
    query.max_results ||= 10;

    return execute('vens', 'autocomplete', {
      params: {
        pversion: pversion || 'draft',
      },
      query,
    });
  },
  compatibilityReport(id) {
    return execute('ven', 'compatibility_report', {
      params: {
        ven_id: id,
        pversion: 'draft',
      },
    });
  },
  getEndpointVENCount({force = false} = {}) {
    return execute('vens', 'get_collection', {
      query: {ven_type: 'endpoint', max_results: 0},
      force,
    });
  },
};

export const orgSettings = {
  // Get org settings
  get() {
    return execute('settings', 'get');
  },
};

export const essentialServiceRules = {
  get(pversion) {
    return execute('essential_service_rules', 'get', {
      params: {
        pversion,
      },
    });
  },
};

// Traffic DB Monitor API
export const trafficDBMonitor = {
  get() {
    return execute('traffic_flows_database_metrics', 'get', {});
  },
};

export default {
  serviceBindings,
  serviceBinding,
  orgs,
  org,
  dns,
  health,
  samlConfigs,
  samlConfig,
  radiusConfigs,
  radiusConfig,
  authenticationSettings,
  authSecPrincipals,
  permissions,
  permission,
  apiKeys,
  apiKey,
  ruleSearch,
  ruleSets,
  ruleSet,
  secRules,
  secRule,
  securityPrincipals,
  securityPrincipal,
  labels,
  label,
  users,
  user,
  kvPairs,
  kvPair,
  coreServices,
  roles,
  auditLogEvents,
  trafficEvents,
  workloads,
  workload,
  services,
  service,
  ipLists,
  ipList,
  pairingProfiles,
  pairingProfile,
  firewallSettings,
  secPolicyGraph,
  ruleCoverage,
  agentTraffic,
  appGroupRules,
  managementPorts,
  secPolicies,
  secPolicy,
  workloadOsFamilies,
  agentSupportReportRequests,
  agents,
  systemManagement,
  provision,
  slbs,
  nfcs,
  trafficFlows,
  virtualServers,
  virtualServer,
  discoveredVirtualServers,
  virtualServices,
  virtualService,
  labelGroups,
  labelGroup,
  locationSummary,
  appGroupSummary,
  appGroups,
  secureConnectGateways,
  secureConnectGateway,
  enforcementBoundaries,
  ipTablesRules,
  ipTablesRule,
  containerClusters,
  containerWorkloads,
  containerWorkload,
  venReleases,
  vens,
  vulnerabilityInstances,
  vulnerabilityReports,
  optionalFeatures,
  networks,
  orgSettings,
  essentialServiceRules,
  trafficDBMonitor,
};
