/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import intl from '@illumio-shared/utils/intl';
import cx from 'classnames';
import {State, Link} from 'react-router';
import Constants from '../constants';
import actionCreators from '../actions/actionCreators';
import {ConnectivityStore, JoinedConnectivityStore, RulesetStore, SessionStore, OrgStore} from '../stores';
import {RouterMixin, StoreMixin, UserMixin, UnsavedChangesMixin} from '../mixins';
import {
  RestApiUtils,
  RulesetUtils,
  GridDataUtils,
  IpUtils,
  PortUtils,
  ServiceUtils,
  ProviderConsumerUtils,
} from '../utils';
import {
  Button,
  Icon,
  NotificationGroup,
  SpinnerOverlay,
  Tally,
  WorkloadAndContainerWorkloadSelect,
  RuleGrid,
  Label,
  LabelGroup,
  Banner,
} from '../components';
import {Select} from '../components/FormComponents';

function getStateFromStores() {
  return {
    rulesetsCountDraft: RulesetStore.getCountByVersion('draft'),
    rulesetsCountActive: RulesetStore.getCountByVersion('active'),
    rulesets: JoinedConnectivityStore.getAll(),
    rulesLength: JoinedConnectivityStore.getRulesLength(),
    status: [RulesetStore.getStatus(), ConnectivityStore.getStatus()],
  };
}

export default Object.assign(
  React.createClass({
    mixins: [
      State,
      RouterMixin,
      UserMixin,
      UnsavedChangesMixin,
      StoreMixin([RulesetStore, ConnectivityStore, JoinedConnectivityStore], getStateFromStores),
    ],

    getInitialState() {
      return {
        providers: [],
        consumers: [],
        service: '',
        network: 'brn',
        errors: {
          providers: '',
          consumers: '',
          service: '',
          network: '',
        },
        showSpinner: true,
      };
    },

    async componentDidMount() {
      if (SessionStore.isUserWithReducedScope()) {
        return;
      }

      await RestApiUtils.ruleSets.getCollection({representation: 'rule_set_scopes'}, 'draft');
      await RestApiUtils.ruleSets.getCollection({representation: 'rule_set_scopes'}, 'active');
      this.setState({showSpinner: false});
    },

    componentWillMount() {
      if (SessionStore.isUserWithReducedScope()) {
        this.replaceWith('landing');
      }
    },

    componentWillUnmount() {
      actionCreators.clearConnectivity();
    },

    getDisabled(errors) {
      return (
        Boolean(errors.providers || errors.consumers || errors.service || errors.network) ||
        !this.state.providers.length ||
        !this.state.consumers.length ||
        !this.state.network
      );
    },

    getErrors(providers, consumers, service, network) {
      const errors = {
        providers: '',
        consumers: '',
        service: '',
        network: '',
      };
      let invalidIp;

      if (this.state.checked && !providers.length) {
        errors.providers = intl('Connectivity.ProviderProvider');
      }

      if (this.state.checked && !consumers.length) {
        errors.consumers = intl('Connectivity.ConsumerMissing');
      }

      if (providers.length && providers[0].ipAddress && !IpUtils.isValidIP(providers[0].ipAddress)) {
        errors.providers = intl('Common.IPAddressInvalid');
        invalidIp = true;
      }

      if (consumers.length && consumers[0].ipAddress && !IpUtils.isValidIP(consumers[0].ipAddress)) {
        errors.consumers = intl('Common.IPAddressInvalid');
        invalidIp = true;
      }

      if (
        providers.length &&
        !providers[0].ipAddress &&
        consumers.length &&
        !consumers[0].ipAddress &&
        network !== 'brn'
      ) {
        errors.network = intl('Connectivity.NonCorporateMustUseIpListError');
      }

      if (
        !invalidIp &&
        providers.length > 0 &&
        providers[0].ipAddress &&
        consumers.length > 0 &&
        consumers[0].ipAddress
      ) {
        errors.providers = errors.consumers = intl('Connectivity.BothEntitiesCantBeIP');
      }

      if (service) {
        const serviceError = this.validatePortProtocol(service.trim());

        if (serviceError) {
          errors.service = serviceError;
        }
      }

      return errors;
    },

    getResults() {
      if (this.state.rulesets === null) {
        return null;
      }

      return this.state.rulesets.length > 0 ? (
        <div className="ConnectivityCheck-results">
          <Banner type="ok" header={intl('Connectivity.RulesAllowConnection')} />
          <Tally type="notice" label={intl('Common.Rulesets')} count={this.state.rulesets.length} tid="rulesets" />
          <Tally type="notice" label={intl('Common.Rules')} count={this.state.rulesLength} tid="rules" />
        </div>
      ) : (
        <Banner
          type="error"
          header={intl('Connectivity.RulesAllowConnectionFail')}
          message={intl('Connectivity.RulesAllowConnectionFailMessage')}
        />
      );
    },

    getRulesets(providerConsumerOrder) {
      if (this.state.rulesets) {
        return this.state.rulesets.map(ruleset => {
          let renderScope = [];

          const scope = RulesetUtils.getScopesWithLabelKeys(ruleset.scopes)[0];

          if (_.isEmpty(scope)) {
            renderScope.push(<Label text={intl('Common.All')} icon="scope" />);
          } else {
            renderScope = Object.values(scope).map(item => {
              const tid = `ruleset-${item.key}-scope`;
              const labelProps = {
                type: item.key,
                exclusion: item.exclusion,
                text: item.name ? item.name : item.value,
              };

              return (
                <span data-tid={tid}>{item.name ? <LabelGroup {...labelProps} /> : <Label {...labelProps} />}</span>
              );
            });
          }

          let moreScopes;

          if (ruleset.scopes.length > 1) {
            const additional = ruleset.scopes.length - 1;

            moreScopes = (
              <span className="ConnectivityCheck-ruleset-scope-count">{`+${additional} ${intl('Common.More')}`}</span>
            );
          }

          const routeParams = {
            id: GridDataUtils.getIdFromHref(ruleset.href),
            pversion: 'draft',
            tab: 'intrascope',
          };

          return ruleset.matchedRules.length ? (
            <div className="ConnectivityCheck-ruleset">
              <div className="ConnectivityCheck-ruleset-summary" data-tid="ruleset-summary">
                <span className="ConnectivityCheck-ruleset-name" data-tid="ruleset-name">
                  {intl('Common.Ruleset')}
                  <span data-tid="ruleset-link">
                    <Link className="ConnectivityCheck-ruleset-link" to="rulesets.item" params={routeParams}>
                      {ruleset.name}
                    </Link>
                  </span>
                </span>
                <span className="ConnectivityCheck-ruleset-labels">{renderScope}</span>
                {moreScopes}
              </div>
              <RuleGrid
                rules={ruleset.matchedRules}
                version="draft"
                rulesetEnabled={ruleset.enabled}
                providerConsumerOrder={providerConsumerOrder}
              />
            </div>
          ) : null;
        });
      }
    },

    getSelections(providerConsumerOrder) {
      const classes = cx({
        'RulesetRules-add': true,
        'ConnectivityCheck-selection': true,
        'ConnectivityCheck-selection-top': this.state.checked,
        'ConnectivityCheck-selection-fullpage': !this.state.checked,
      });
      const errors = this.state.errors;
      const disabled = this.getDisabled(errors);
      let errorOutput = null;
      const networkTypeOptions = [
        {value: 'brn', label: intl('Common.Corporate')},
        {value: 'non_brn', label: intl('Rulesets.Rules.NonCorporateNetworks')},
        {value: 'any', label: intl('Protocol.Any')},
      ];

      if (errors.providers || errors.consumers || errors.service || errors.network) {
        errorOutput = (
          <div data-tid="elem-errors" className="ConnectivityCheck-errors">
            {[
              ProviderConsumerUtils.setProviderConsumerColumnOrder(
                errors.providers ? (
                  <div className="ConnectivityCheck-error">
                    <Icon name="error" />
                    <span data-tid="error-provider">
                      {`${intl('Connectivity.ProviderError')}: ${errors.providers}`}
                    </span>
                  </div>
                ) : null,
                errors.service ? (
                  <div className="ConnectivityCheck-error">
                    <Icon name="error" />
                    <span data-tid="error-service">{`${intl('Connectivity.ServiceError')}: ${errors.service}`}</span>
                  </div>
                ) : null,
                errors.consumers ? (
                  <div className="ConnectivityCheck-error">
                    <Icon name="error" />
                    <span data-tid="error-consumer">
                      {`${intl('Connectivity.ConsumerError')}: ${errors.consumers}`}
                    </span>
                  </div>
                ) : null,
                providerConsumerOrder,
                errors.network ? (
                  <div className="ConnectivityCheck-error">
                    <Icon name="error" />
                    <span data-tid="error-network">{`${intl('Connectivity.NetworkError')}: ${errors.network}`}</span>
                  </div>
                ) : null,
              ),
            ]}
          </div>
        );
      }

      return (
        <div className={classes}>
          {errorOutput}
          <table className="Grid">
            <tbody>
              <tr>
                {[
                  ProviderConsumerUtils.setProviderConsumerColumnOrder(
                    <td data-tid="elem-provider">
                      <strong>{intl('Common.Destination')}</strong>
                    </td>,
                    <td data-tid="elem-service">
                      <strong>{intl('Connectivity.PortAndProtocol')}</strong>
                    </td>,
                    <td data-tid="elem-consumer">
                      <strong>{intl('Common.Source')}</strong>
                    </td>,
                    providerConsumerOrder,
                    <td data-tid="elem-network">
                      <strong>{intl('Common.NetworkProfile')}</strong>
                    </td>,
                  ),
                ]}
              </tr>
              <tr>
                {ProviderConsumerUtils.setProviderConsumerColumnOrder(
                  <td className="Grid-cell-provider-select" data-tid="provider">
                    <WorkloadAndContainerWorkloadSelect
                      placeholder={intl('Connectivity.SelectWorkloadContainerWorkloadOrIP')}
                      onChange={this.handleProviderChange}
                      allowPartial={true}
                    />
                  </td>,
                  <td className="Grid-cell-service-select" data-tid="port-protocol">
                    <input
                      onChange={this.handleServiceChange}
                      onBlur={this.handleServiceBlur}
                      value={this.state.service}
                      placeholder={intl('Connectivity.Example22TCP')}
                      className="Form-input"
                    />
                  </td>,
                  <td className="Grid-cell-select-consumers" data-tid="consumer">
                    <WorkloadAndContainerWorkloadSelect
                      placeholder={intl('Connectivity.SelectWorkloadContainerWorkloadOrIP')}
                      onChange={this.handleConsumerChange}
                      allowPartial={true}
                    />
                  </td>,
                  providerConsumerOrder,
                  <td className="Grid-cell-select-consumers" data-tid="consumer">
                    <Select
                      options={networkTypeOptions}
                      onChange={this.handleNetworkTypeChange}
                      value={this.state.network}
                      tid="version"
                    />
                  </td>,
                )}
                {this.state.checked ? (
                  <td className="Grid-cell-rule-add-button">
                    <Button
                      text={intl('Connectivity.CheckRules')}
                      disabled={disabled}
                      onClick={this.handleCheck}
                      tid="check-connectivity"
                    />
                  </td>
                ) : null}
              </tr>
            </tbody>
          </table>
          {this.state.checked ? null : (
            <div className="ConnectivityCheck-button-fullpage">
              <Button
                text={intl('Connectivity.CheckRules')}
                disabled={disabled}
                onClick={this.handleCheck}
                tid="check-connectivity"
              />
            </div>
          )}
        </div>
      );
    },

    async checkRulesets(draftRules, activeRules) {
      if (this.state.rulesetsCountDraft.total <= 450 && this.state.rulesetsCountActive.total <= 450) {
        // If the system has less than 500 rules, then all the Rulesets
        // already exist in the RulesetStore.
        // 450 instead of 500 as the count estimate (the system to get
        // total and matched count) works with an error margin of 10%.
        this.check(true);

        return;
      }

      this.setState({showSpinner: true});

      // Store all the unique Ruleset Hrefs
      const draftRulesetsHrefs = new Set(draftRules.map(rule => rule.href.split('/').slice(0, -2).join('/')));
      const activeRulesetsHrefs = new Set(activeRules.map(rule => rule.href.split('/').slice(0, -2).join('/')));

      // Store all the Ruleset Hrefs which are not in RulesetStore
      const draftRulesetsNotInStore = new Set();
      const activeRulesetsNotInStore = new Set();

      draftRulesetsHrefs.forEach(href => {
        if (!RulesetStore.getSpecified(href, 'rule_set_scopes')) {
          draftRulesetsNotInStore.add(href);
        }
      });

      activeRulesetsHrefs.forEach(href => {
        if (!RulesetStore.getSpecified(href, 'rule_set_scopes')) {
          activeRulesetsNotInStore.add(href);
        }
      });

      // Query all the Rulesets not present in the RulesetStore
      await Promise.all(
        Array.from(draftRulesetsNotInStore).map(href =>
          RestApiUtils.ruleSets.cachedGetInstance(href.split('/').pop(), 'draft'),
        ),
      );

      await Promise.all(
        Array.from(activeRulesetsNotInStore).map(href =>
          RestApiUtils.ruleSets.cachedGetInstance(href.split('/').pop(), 'active'),
        ),
      );

      this.setState({showSpinner: false});
      this.check(true);
    },

    handleCheck() {
      this.check();
    },

    async check(preventRulesetsCheck) {
      const errors = this.getErrors(this.state.providers, this.state.consumers, this.state.service, this.state.network);

      if (this.getDisabled(errors)) {
        return;
      }

      const src = this.state.consumers[0];
      const dst = this.state.providers[0];
      const allow = {};
      const service = this.parsePortProtocol(this.state.service.trim());
      const network = this.state.network;

      if (service) {
        if (service.port) {
          allow.port = service.port;
        }

        if (service.protocol) {
          switch (service.protocol) {
            case 'tcp':
              allow.protocol = 6;
              break;
            case 'udp':
              allow.protocol = 17;
              break;
            case 'gre':
              allow.protocol = 47;
              break;
            case 'ipip':
              allow.protocol = 94;
              break;
            case 'icmp':
              allow.protocol = 1;
              break;
            case 'icmpv6':
              allow.protocol = 58;
              break;
            case 'igmp':
              allow.protocol = 2;
              break;
            //no default
          }
        }
      }

      if (src.workloads) {
        allow.src_workload = src.workloads.href;
      } else if (src.container_workloads) {
        allow.src_container_workload = src.container_workloads.href;
      } else {
        allow.src_external_ip = src.ipAddress;
      }

      if (dst.workloads) {
        allow.dst_workload = dst.workloads.href;
      } else if (dst.container_workloads) {
        allow.dst_container_workload = dst.container_workloads.href;
      } else {
        allow.dst_external_ip = dst.ipAddress;
      }

      if (network === 'brn' || network === 'non_brn') {
        allow.network_type = network;
      }

      // Add {noCache: true} option to not cache when user makes the request
      const {body: draftRules} = await RestApiUtils.secPolicy.allow(allow, 'draft', {noCache: true});
      const {body: activeRules} = await RestApiUtils.secPolicy.allow(allow, 'active', {noCache: true});

      if (!preventRulesetsCheck) {
        // Make sure all the Rulesets which belong to the Rules are in
        // the RulesetStore, or fetch them if they're not
        this.checkRulesets(draftRules, activeRules);

        return;
      }

      this.setState({checked: true});
    },

    handleConsumerChange(added, removed) {
      const consumers = this.handleWorkloadChange(this.state.consumers.slice(), added, removed);

      this.setState({
        consumers,
        errors: this.getErrors(this.state.providers, consumers, this.state.service, this.state.network),
      });
    },

    handleProviderChange(added, removed) {
      const providers = this.handleWorkloadChange(this.state.providers.slice(), added, removed);

      this.setState({
        providers,
        errors: this.getErrors(providers, this.state.consumers, this.state.service, this.state.network),
      });
    },

    handleServiceBlur() {
      this.setState({
        errors: this.getErrors(this.state.providers, this.state.consumers, this.state.service, this.state.network),
      });
    },

    handleServiceChange(evt) {
      const value = evt.target.value;
      const errors = this.getErrors(this.state.providers, this.state.consumers, value, this.state.network);
      const state = {service: value};

      if (this.state.errors.service && !errors.service) {
        state.errors = errors;
      }

      this.setState(state);
    },

    handleNetworkTypeChange(value) {
      this.setState({
        network: value,
        errors: this.getErrors(this.state.providers, this.state.consumers, this.state.service, value),
      });
    },

    handleWorkloadChange(entities, added, removed) {
      if (added) {
        if (added.href) {
          entities = [
            {
              [added.href.split('/')[3]]: {
                href: added.href,
              },
            },
          ];
        } else {
          entities = [
            {
              ipAddress: added.value,
            },
          ];
        }
      } else if (removed) {
        entities = [];
      }

      return entities;
    },

    hasChanged() {
      if (!this.rule) {
        return false;
      }

      return this.state.service || this.state.providers || this.state.consumers;
    },

    parsePortProtocol(service) {
      const parts = service.toLowerCase().split(' ');

      if (parts.length === 2) {
        return {
          port: parts[0],
          protocol: parts[1],
        };
      }

      if (parts.length === 1) {
        if (String(Math.trunc(Number(parts[0]))) !== parts[0]) {
          return {
            protocol: parts[0],
          };
        }

        return {
          port: parts[0],
        };
      }

      return {};
    },

    validatePortProtocol(service) {
      const {protocol, port} = this.parsePortProtocol(service);

      if (protocol && !port) {
        if (!PortUtils.getProtocols().includes(ServiceUtils.reverseLookupProtocol(protocol))) {
          return `${intl('Common.ProtocolInvalid')}. ${intl('Connectivity.Example22TCP')}`;
        }

        if (PortUtils.getProtocolsWithPorts().includes(ServiceUtils.reverseLookupProtocol(protocol))) {
          return `${intl('Port.Missing')}. ${intl('Connectivity.Example22TCP')}`;
        }
      } else if (port && !ServiceUtils.reverseLookupProtocol(protocol)) {
        return `${intl('Common.ProtocolMissing')}. ${intl('Connectivity.Example22TCP')}`;
      } else if (port && ServiceUtils.reverseLookupProtocol(protocol)) {
        if (!PortUtils.getProtocols().includes(ServiceUtils.reverseLookupProtocol(protocol))) {
          return `${intl('Common.ProtocolInvalid')}. ${intl('Connectivity.Example22TCP')}`;
        }

        if (PortUtils.getProtocolsWithoutPorts().includes(ServiceUtils.reverseLookupProtocol(protocol))) {
          return `${intl('Port.ProtocolInvalid')}. ${intl('Connectivity.Example22TCP')}`;
        }

        if (!PortUtils.isValidPort(port)) {
          return intl('Port.InvalidPortValue');
        }
      } else {
        return `${intl('Port.ProtocolInvalid')}. ${intl('Connectivity.Example22TCP')}`;
      }
    },

    render() {
      const providerConsumerOrder = OrgStore.providerConsumerOrder();
      const selections = this.getSelections(providerConsumerOrder);
      let rulesets = null;
      let results = null;

      if (this.state.checked && !this.state.status.includes(Constants.STATUS_BUSY)) {
        rulesets = this.getRulesets(providerConsumerOrder);
        results = this.getResults();
      }

      const notification = {
        type: 'instruction',
        title: intl('Connectivity.VerifyRulesExist'),
      };

      return (
        <div className="ConnectivityCheck">
          {this.state.status.includes(Constants.STATUS_BUSY) || this.state.showSpinner ? <SpinnerOverlay /> : null}
          <NotificationGroup notifications={[notification]} displayStyle={this.state.checked ? 'shaded' : undefined} />
          {selections}
          {results}
          {rulesets}
        </div>
      );
    },
  }),
  {
    viewName: () => intl('Policy.Check'),
    isAvailable: () => !SessionStore.isUserWithReducedScope(),
  },
);
