/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import cx from 'classnames';
import intl from '@illumio-shared/utils/intl';
import React, {PropTypes} from 'react';
import {RuleNote} from '../RuleWriting';
import {Grid, Icon, Label, Banner} from '..';
import {GridDataUtils, ProviderConsumerUtils, RulesetUtils} from '../../utils';

export default React.createClass({
  propTypes: {
    currentPage: PropTypes.number,
    rules: PropTypes.array.isRequired,
    onRowClick: PropTypes.func.isRequired,
    onSort: PropTypes.func.isRequired,
    hideColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  },

  getInitialState() {
    return {
      showNote: null,
      showNoteTop: false,
    };
  },

  componentDidMount() {
    document.addEventListener('click', this.handleCompClick);
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleCompClick);
  },

  getRuleNote(value, row) {
    const {showNote, showNoteTop} = this.state;

    return (
      <RuleNote
        isReadOnly
        href={row.href}
        onNoteIconClick={this.handleNoteIconClick}
        onNoteCloseClick={this.handleNoteCloseClick}
        value={value}
        showNote={row.href === showNote}
        showNoteTop={showNoteTop}
        onSetNoteBox={this.handleSetNoteBox}
      />
    );
  },

  handleCompClick(evt) {
    const isNoteIcon = evt.target.classList.contains('Icon-RulesetRules-NoteIcon');

    if (!this.state.showNote || !this.noteBox || isNoteIcon) {
      return;
    }

    if (!this.noteBox.contains(evt.target)) {
      this.handleNoteCloseClick();
    }
  },

  handleNoteCloseClick() {
    this.setState({showNote: null});
  },

  handleNoteIconClick(href, showNoteTop) {
    if (this.state.showNote === href) {
      this.setState({showNote: null});

      return;
    }

    this.setState({showNote: href, showNoteTop});
  },

  handleSetNoteBox(node) {
    this.noteBox = node;
  },

  getRowClickable(row) {
    return Boolean(row.rule_set.href);
  },

  formatRulesetName(rulesetName, rule) {
    if (!rule.rule_set.href) {
      return rulesetName;
    }

    return <span className="RuleSearch-rulesetname-link">{rulesetName}</span>;
  },

  formatAccess(href, rule) {
    if (!href) {
      return intl('Common.None');
    }

    return rule.rule_set.caps?.includes('write') ? intl('RuleSearch.Editable') : intl('RuleSearch.ReadOnly');
  },

  formatScopes(scopes) {
    if (scopes[0].length === 0) {
      return (
        <span key="all-app">
          <Label text={intl('Common.All')} icon="scope" />
        </span>
      );
    }

    return scopes.map((scope, idx) => (
      <div key={idx}>
        {Object.values(
          scope.reduce((result, value) => {
            const item = value.label || value.label_group;

            result[item.key] = (
              <span key={item.href}>{RulesetUtils.formatScopeLabel(item.key, item, value.exclusion)}</span>
            );

            return result;
          }, {}),
        )}
      </div>
    ));
  },

  formatStatus(value, rule) {
    if (!value || !rule.rule_set.enabled) {
      return intl('Common.Disabled');
    }

    return intl('Common.Enabled');
  },

  formatRuleAttribute(value) {
    return value ? intl('Common.On') : intl('Common.Off');
  },

  formatSecConnect(value) {
    return value ? [<Icon name="lock" size="large" />, ' ', intl('Common.On')] : intl('Common.Off');
  },

  formatUnscopedConsumers(value) {
    if (value === true) {
      return (
        <span>
          <Icon name="global" size="large" /> {intl('RuleSearch.Extra')}
        </span>
      );
    }
  },

  render() {
    const {hideColumns, onRowClick} = this.props;
    const {rules, providerConsumerOrder} = this.props;
    const disabledRulesetExists = rules.some(rule => !rule.rule_set.enabled || !rule.enabled);

    const columns = [
      {
        key: 'update_type',
        label: intl('Provision.Status'),
        style: 'provision',
        sortable: true,
        format(value) {
          return GridDataUtils.formatUpdatedType(value);
        },
      },
      {
        key: 'rule_set.scopes',
        label: intl('Common.Scopes'),
        style: 'scopes',
        format: this.formatScopes,
      },
      ...ProviderConsumerUtils.setProviderConsumerColumnOrderArrow(
        {
          key: 'providers',
          label: intl('Common.Destinations'),
          style: 'providers',
          sortable: true,
          sortValue: GridDataUtils.sortRuleEntitiesValue,
          format: (value, row) => GridDataUtils.formatRuleEntities(value, row),
        },
        {
          key: 'ingress_services',
          label: __ANTMAN__ ? intl('Common.Service') : intl('Rulesets.Rules.ProvidingService'),
          style: 'service',
          sortable: true,
          sortValue: GridDataUtils.sortRuleServiceValue,
          format: GridDataUtils.formatRuleService,
        },
        {
          key: 'consumer_to_provider_arrow',
          style: 'consumerToProviderArrow',
        },
        {
          key: 'consumers',
          label: intl('Common.Sources'),
          style: 'consumers',
          sortable: true,
          sortValue: GridDataUtils.sortRuleEntitiesValue,
          format: (value, row) => GridDataUtils.formatRuleEntities(value, row, true),
        },
        providerConsumerOrder,
      ),
      {
        key: 'enabled',
        label: intl('Common.Status'),
        style: 'status',
        sortable: true,
        type: 'boolean',
        format: this.formatStatus,
      },
      {
        key: 'sec_connect',
        label: (
          <span>
            {!__ANTMAN__ && <Icon name="lock" size="small" />}
            {` ${intl('Common.SecureConnect')}`}
          </span>
        ),
        style: 'sec-connect',
        sortable: true,
        type: 'boolean',
        format: this.formatSecConnect,
      },
      {
        key: 'machine_auth',
        label: intl('Common.MachineAuthentication'),
        style: 'machine-auth',
        sortable: true,
        type: 'boolean',
        format: this.formatRuleAttribute,
      },
      {
        key: 'stateless',
        label: intl('Common.Stateless'),
        style: 'stateless',
        sortable: true,
        type: 'boolean',
        format: this.formatRuleAttribute,
      },
      {
        key: 'unscoped_consumers',
        sortable: true,
        style: 'unscoped-consumers',
        type: 'boolean',
        label: (
          <span>
            <Icon name="global" size="large" />
            {` ${intl('RuleSearch.Extra')}`}
          </span>
        ),
        format: this.formatUnscopedConsumers,
      },
      {
        key: 'rule_set.name',
        sortable: true,
        style: 'ruleset-name',
        label: intl('Common.Ruleset'),
        format: this.formatRulesetName,
      },
      {
        key: 'rule_set.href',
        label: intl('RuleSearch.Access'),
        style: 'access',
        sortable: true,
        type: 'boolean',
        format: this.formatAccess,
      },
      {
        key: 'description',
        sortable: true,
        label: intl('Common.Note'),
        style: 'description',
        // Show hidden text for "Download"
        format: (value, row) => (
          <div>
            <div className="RuleSearchHideText">{value}</div>
            {this.getRuleNote(value, row)}
          </div>
        ),
      },
      {
        key: 'created_at',
        sortable: true,
        style: 'created-at',
        type: 'date',
        label: intl('Labels.Detail.CreatedAt'),
        format: GridDataUtils.formatDate,
      },
      {
        key: 'updated_at',
        sortable: true,
        style: 'updated-at',
        type: 'date',
        label: intl('Common.UpdatedAt'),
        format: GridDataUtils.formatDate,
      },
      {
        key: 'created_by_user',
        style: 'user',
        label: intl('Common.CreatedBy'),
        sortable: true,
      },
      {
        key: 'updated_by_user',
        style: 'user',
        label: intl('Common.LastModifiedBy'),
        sortable: true,
      },
    ].filter(({key}) => !hideColumns.includes(key));

    const classNames = cx('RuleGrid', 'RuleSearchGrid', {
      'RuleSearchGrid--DisabledRulesetExists': disabledRulesetExists,
    });

    return (
      <div className={classNames}>
        <Grid
          columns={columns}
          data={rules}
          onRowClick={onRowClick}
          rowClickable={this.getRowClickable}
          onSort={this.props.onSort}
          sortable
          sorting={this.props.sorting}
          currentPage={this.props.currentPage}
          resultsPerPage={this.props.pageLength}
          emptyContent={<Banner type="notice" header={intl('Common.NoMatchRules')} />}
        />
      </div>
    );
  },
});
